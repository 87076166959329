import React, { useEffect, useState } from "react";

import Layout from "../components/Layout/Layout";
import NavBar from "../components/NavBar/NavBar";
import "./invite.css";
// import friendsIcon from "../assets/friends.svg";
import telegramUser from "../assets/telegramUser.svg";
import telegramPremium from "../assets/telegramPremium.svg";
import coinIcon from "../assets/coin.png";

import { getProfile } from "../api/getProfile";

import { handleInvite } from "../utils/handleInvite";
import { formatNumber } from "../utils/formatNumber";
import Header from "../components/Header/Header";
import { useNavigate } from "react-router-dom";

const Invite = () => {
  const normalReferralCount = +sessionStorage.getItem("normalReferralCount");
  const premiumReferralCount = +sessionStorage.getItem("premiumReferralCount");
  const referralBonus = formatNumber(
    normalReferralCount * 1500 + premiumReferralCount * 5000
  );

  const [memberData, setMemberData] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [isEnglish, setIsEnglish] = useState(false);
  const navigate = useNavigate();

  // useEffect
  useEffect(() => {
    const savedLanguage = sessionStorage.getItem("isEnglish");
    if (savedLanguage === "true") {
      setIsEnglish(true);
    } else {
      setIsEnglish(false);
    }

    const getFriendsInfo = async () => {
      const token = sessionStorage.getItem("token");

      const data = await getProfile(token);
      let friends = data.friends;
      let arr = [];
      friends.map((friend) => {
        let score = friend.score;
        let userName = "@" + friend.userName;
        let scoreText = score > 1000 ? `${score / 1000}k+` : `${score}`;
        arr.push({ id: userName, scoreText });
      });
      setMemberData(arr);
    };
    getFriendsInfo();
  }, []);

  const handleInviteClick = async () => {
    const chatId = sessionStorage.getItem("chatId");
    const userName = sessionStorage.getItem("userName");
    setIsloading(true);
    await handleInvite(chatId, userName);
    setIsloading(false);
    if (
      window.Telegram &&
      window.Telegram.WebApp &&
      window.Telegram.WebApp.close
    ) {
      window.Telegram.WebApp.close();
    } else {
      console.error("Telegram WebApp API is not available.");
    }
  };

  return (
    <Layout>
      {isLoading ? (
        <div className="loading-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <>
          {/* <Header/> */}
          <div style={{ marginTop: "67px" }}>
            {/* <img
              src="/Images/back-arrow.svg"
              alt="back"
              onClick={() => {
                navigate(-1);
              }}
            /> */}
          </div>
          <div
            style={{
              backgroundImage: "url('/Images/Profile/boys.png')",
              //   height: "419px",
              backgroundRepeat: "no-repeat",
              backgroundSize: "350px 320px",
              backgroundPosition: "top center",
            }}
          >
            <div
              style={{
                background:
                  "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 46.52%)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  paddingBlock: "1.5rem",
                  paddingTop: "300px",
                  paddingBottom: "134px",
                }}
              >
                <div style={{ position: "relative" }}>
                  <h1
                    style={{
                      color: "transparent",
                      fontFamily: "MontserratBold",
                      // fontSize: "68px",
                      fontWeight: "800",
                      background:
                        "linear-gradient(91.61deg, #5D01A2 1.37%, #FFF204 169.85%)",
                      filter: "blur(30px)",
                      position: "relative",
                      zIndex: "1",
                      backgroundClip: "text",
                    }}
                    className="invite-toptext"
                  >
                    {normalReferralCount + premiumReferralCount}{" "}
                    {isEnglish ? "Friends" : "Amici"}
                  </h1>
                  <h1
                    style={{
                      color: "#fff",
                      fontFamily: "MontserratBold",
                      // fontSize: "68px",
                      fontWeight: "800",
                      position: "absolute",
                      zIndex: "2",
                      top: "0",
                    }}
                    className="invite-toptext"
                  >
                    {normalReferralCount + premiumReferralCount}{" "}
                    {isEnglish ? "Friends" : "Amici"}
                  </h1>
                </div>

                <div style={{ width: "100%" }} className="middle-invite">
                  <button
                    style={{
                      // width: "220px",
                      // height: "50px",
                      background:
                        "linear-gradient(#101010, #101010) padding-box,linear-gradient(92.23deg,rgba(255, 0, 217, 0.6) -19.69%,rgba(108, 78, 254, 0.6) 100%) border-box",
                      paddingBlock: "1.125rem",
                      border: "2px solid transparent",
                      borderRadius: "100px",
                      color: "white",
                      fontFamily: "Montserrat",
                      fontSize: "1.125rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "10px 25px",
                      margin: "auto",
                      marginTop: "19px",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "MontserratBold",

                        fontWeight: "800",
                        color: "transparent",
                        background:
                          "linear-gradient(90.24deg, #DCE64F 0.06%, #F8FF96 43.96%)",
                        backgroundClip: "text",
                        fontStyle: "italic",
                        paddingInline: "2.5px",
                      }}
                      className="points-invite-be"
                    >
                      +{referralBonus}{" "}
                    </span>{" "}
                    &nbsp;{" "}
                    <span
                      style={{
                        fontWeight: "600",
                        color: "rgba(255, 255, 255, 1)",
                      }}
                      className="points-invite-be1"
                    >
                      {isEnglish ? "Points" : "Punti"}
                    </span>
                  </button>
                  <div style={{ marginTop: "2.1875rem" }}>
                    <span
                      style={{
                        fontWeight: "600",

                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "25px",
                        color: "transparent",
                        background:
                          "linear-gradient(90.24deg, #DCE64F 0.06%, #F8FF96 43.96%)",
                        backgroundClip: "text",
                        // letterSpacing:"1px",
                        fontFamily: "Montserrat",
                      }}
                      className="invite-textline"
                    >
                      {isEnglish
                        ? "Invite friends to earn more points!"
                        : "Invita amici per guadagnare più punti!"}
                    </span>
                  </div>
                  <div
                    style={{
                      //   marginTop: "1.875rem",
                      width: "100%",
                      //   height: "177px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      borderRadius: "20px",
                      background: "#090909",
                      border: "2px solid #121212",
                      margin: "auto",
                      //   marginBottom: "20px",
                      marginTop: "20px",
                    }}
                    className="for-padding"
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexDirection: "column",
                        gap: "5px",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                          // height: "49px",
                          borderRadius: "15px",
                          padding: "12px 15px",
                          background: "#0E0E0E",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <img
                            src={telegramUser}
                            alt="Telegram User"
                            //   style={{ width: "25px", height: "25px" }}
                          />
                          <span
                            style={{
                              fontFamily: "Montserrat",
                              fontWeight: "500",

                              color: "#FFFFFF",

                              letterSpacing: "0.5px",
                            }}
                            className="telegram-user-text"
                          >
                            {isEnglish ? "Telegram User" : "Utente Telegram"}
                          </span>
                        </div>
                        <span
                          style={{
                            fontFamily: "MontserratBold",

                            color: "#FFFFFF",
                            fontStyle: "italic",
                            fontWeight: "800",
                          }}
                          className="telegram-points"
                        >
                          1,500
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                          height: "49px",
                          borderRadius: "15px",
                          padding: "12px 15px 12px 15px",
                          background: "#0E0E0E",
                          //   gap: "15",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <img
                            src={telegramPremium}
                            alt="Telegram Premium"
                            //   style={{ width: "3.125rem", height: "3.125rem" }}
                          />
                          <span
                            style={{
                              // marginTop: "0.9375rem",
                              fontFamily: "Montserrat",
                              fontWeight: "500",

                              letterSpacing: "0.5px",
                              color: "#FFFFFF",
                            }}
                            className="telegram-user-text"
                          >
                            {isEnglish ? "Premium User" : "Utente Premium"}
                          </span>
                        </div>
                        <span
                          style={{
                            fontFamily: "MontserratBold",

                            color: "#FFFFFF",
                            fontWeight: "800",
                            fontStyle: "italic",
                          }}
                          className="telegram-points"
                        >
                          5,000
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: "1.25rem",
                        fontFamily: "Montserrat",
                        fontWeight: "600",

                        color: "#FFFFFF",
                        letterSpacing: "0.5px",
                      }}
                      className="for-you-text"
                    >
                      {isEnglish ? "For you and a friend" : "Per te e un amico"}
                    </div>
                  </div>
                  <button
                    style={{
                      width: "100%",

                      background:
                        "linear-gradient(92.23deg, #FF00D9 -19.69%, #6C4EFE 100%)",

                      paddingBlock: "1.125rem",
                      border: "none",
                      //   marginTop: "2.5rem",
                      borderRadius: "18.75rem",
                      color: "white",

                      cursor: "pointer",
                      fontWeight: "600",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "auto",
                      fontFamily: " Montserrat",

                      marginTop: "33px",
                    }}
                    className="invite-button"
                    onClick={handleInviteClick}
                  >
                    {isEnglish ? "Invite a Friend" : "Invita un Amico"}
                  </button>
                  {memberData.length ? (
                    <div
                      style={{
                        marginTop: "30px",
                        marginBottom: "0.8rem",
                        width: "100%",
                        // maxHeight: "20rem",
                        // overflow: "auto",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        borderRadius: "1.25rem",
                        background: "#090909",
                        backdropFilter: "blur(0.5rem)",
                        WebkitBackdropFilter: "blur(0.5rem)",
                        border: "2px solid #121212",
                      }}
                      className="invite__data"
                    >
                      {memberData.map((item) => (
                        <div
                          key={item.userId}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "0.8rem",
                            width: "100%",
                            justifyContent: "space-between",
                            background: "#0E0E0E",
                            padding: "12px 15px",
                            borderRadius: "15px",
                          }}
                        >
                          <div
                            style={{
                              position: "relative",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <img
                              src="/Images/image.png"
                              // height={"10rem"}
                              alt="Friends"
                              className="icon"
                              style={{ width: "25px", height: "25px" }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              // flexDirection: "column",
                              marginLeft: "10px",
                              // gap: "0.3125rem",
                              width: "100%",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "Montserrat",
                                fontWeight: "500",
                                fontSize: "14px",
                                color: "white",
                                lineHeight: "100%",
                              }}
                            >
                              {item.id}
                            </span>
                            <span
                              style={{
                                fontFamily: "MontserratBold",
                                fontWeight: "800",
                                fontSize: "20px",
                                color: "#F7F9FB",
                                fontStyle: "italic",
                                // opacity: "0.6",
                              }}
                            >
                              {item.scoreText}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <NavBar />
    </Layout>
  );
};

export default Invite;
