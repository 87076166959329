import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useNavigate } from "react-router-dom";

import "./NavBar.css";

const NavBar = forwardRef((prop, ref) => {
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState(null);
  const [path, setPath] = useState();

  const handleClick = (buttonName, path) => {
    setActiveButton(buttonName);
    navigate(path);
  };
  useEffect(() => {
    const loc = window.location.pathname;
    setPath(loc);

    console.log(loc);
  });

  // const flip = () => { navigate('/flip'); }
  // const friends = () => { navigate('/invite'); }
  // const earn = () => { navigate('/earn'); }
  // const boosts = () => { navigate('/boosts'); }
  // const handleClickPreSale = () => {
  //     navigate('/presale');
  // }

  const [isEnglish, setIsEnglish] = useState(false);

  // Function to update the child's state
  const updateNavLanguagePref = () => {
    const savedLanguage = sessionStorage.getItem("isEnglish");
    if (savedLanguage === "true") {
      setIsEnglish(true);
    } else {
      setIsEnglish(false);
    }
  };

  // Exposing the function to the parent through the ref
  useImperativeHandle(ref, () => ({
    updateNavLanguagePref,
  }));

  useEffect(() => {
    const savedLanguage = sessionStorage.getItem("isEnglish");

    if (savedLanguage === null) {
      setIsEnglish(true); // Default to English if no value is saved
    } else {
      setIsEnglish(savedLanguage === "true"); // Set based on the saved value
    }
  }, []);

  return (
    <div className="button-row">
      <button
        className={`button-item ${path === "/invite" ? "active" : ""}`}
        onClick={() => handleClick("invite", "/invite")}
        style={{ display: "flex", justifyContent: "space-around", gap: "5px" }}
      >
        <img src="/Images/Navbar/invite.svg" alt="Flip x2" className="icon" />
        <span>{isEnglish ? "Invite" : "Invita"}</span>
      </button>
      <button
        className={`button-item ${path === "/earn" ? "active" : ""}`}
        onClick={() => handleClick("tasks", "/earn")}
      >
        <img src="/Images/Navbar/task.svg" alt="Friends" className="icon" />
        <span>{isEnglish ? "Tasks" : "Compiti"}</span>
      </button>
      <button
        className={`button-item ${
          path === "/joinclan"
            ? "active"
            : path?.includes("/community")
            ? "active"
            : ""
        }`}
        onClick={() => handleClick("rankings", "/joinclan")}
      >
        <img
          src="/Images/Navbar/rank.svg"
          alt="Earn"
          className="icon"
          style={{ width: "50px", height: "50px" }}
        />
        <span style={{ fontSize: "12px", fontWeight: "700" }}>
          {isEnglish ? "Home" : "Iniziale"}
        </span>
      </button>
      <button
        className={`button-item ${
          path === "/redeemfirst" || path === "/Redeem" ? "active" : ""
        }`}
        onClick={() => handleClick("redeem", "/redeemfirst")}
      >
        <img src="/Images/Navbar/redeem.svg" alt="Boosts" className="icon" />
        <span>{isEnglish ? "Redeem" : "Riscatta"}</span>
      </button>
      <button
        className={`button-item ${path === "/editProfile" ? "active" : ""}`}
        onClick={() => handleClick("profile", "/editProfile")}
      >
        <img src="/Images/Navbar/profile.svg" alt="presale" className="icon" />
        <span>{isEnglish ? "Profile" : "Profilo"}</span>
      </button>
    </div>
  );
});

export default NavBar;
