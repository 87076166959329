import React, { useEffect, useRef, useState } from "react";
import "./profile.css";
import Layout from "../../components/Layout/Layout";
import NavBar from "../../components/NavBar/NavBar";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  addUser,
  createUser,
  getUserData,
} from "../../service/redux/middleware/user";
import { ErrorToast, SuccessToast } from "../../components/Toast/Toast";
import ScreenLoader from "../../components/loader/ScreenLoader";
import CircularProgress from "@mui/material/CircularProgress";

import { getProfile } from "../../api/getProfile";
import { getClan } from "../../api/getClan";
import { register } from "../../api/register";
import { updateProfile } from "../../api/updateProfile";
import { isEmpty } from "../../utils/isEmpty";
import { formatUserCount } from "../../utils/formatUserCount";
import { formatNumber } from "../../utils/formatNumber";

const initialCoinSize = 1;
const shrinkRate = 0.005;
const growIncrement = 0.017;
const coinScaleLevel1 = 1.19;
const coinScaleLevel2 = 1.44;
const coinScaleLevel3 = 1.71;
const maxCoinScale = 2;
const maxTime = 30;
const debounceDelay = 800;

const Profile = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Extract query parameters from the URL
  const queryParams = new URLSearchParams(location.search);
  const initialTelegramUsername = queryParams.get("telegramUsername") || "";
  console.log();

  // State variables for input fields
  const [telegramUsername, setTelegramUsername] = useState(
    initialTelegramUsername
  );
  const [name, setName] = useState("");
  const [beUserId, setBeUserId] = useState("");
  const [updateLoader, setUpdateLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isUserAdded, setIsUserAdded] = useState(false); // Add a flag state to prevent multiple addUser calls

  const [points, setPoints] = useState(0);
  const [level, setLevel] = useState(0);
  const [coinScale, setCoinScale] = useState(initialCoinSize);
  const [isVibrating, setIsVibrating] = useState(false);
  const [energyCount, setEnergyCount] = useState(0);
  const [energyPercentage, setEnergyPercentage] = useState(100);
  const [showEnergyModal, setShowEnergyModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [remainingTime, setRemainingTime] = useState(null);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDebouncing, setIsDebouncing] = useState(false);
  const [flyingText, setFlyingText] = useState([]);
  const [flyingTextContent, setFlyingTextContent] = useState("");
  const [initialPointsIncrement, setInitialPointsIncrement] = useState(null);
  const [pointsIncrement, setPointsIncrement] = useState(1);
  const [notification, setNotification] = useState("");
  const [notificationVisibile, setNotificationVisibile] = useState(false);
  const [clan, setClan] = useState(null);
  const [clanInfo, setClanInfo] = useState({});
  const [userName, setUserName] = useState();

  const timerIntervalRef = useRef(null);
  const debounceTimeoutRef = useRef(null);

  const [remainingTimeForNextCharge, setRemainingTimeForNextCharge] = useState(
    30 * 60
  );
  const [maxEnergyCount, setMaxEnergyCount] = useState(0);
  const [isCharging, setIsCharging] = useState(false);
  let interval = "";

  const dispatch = useDispatch();
  console.log(telegramUsername);

  async function setUpUser() {
    try {
      const token = sessionStorage.getItem("token");
      if (!name) {
        ErrorToast("Pleas Enter your Name");
        return;
      }
      setIsLoading(true);
      // Usage
      const datas = await validateCustomer(beUserId);

      const data = await updateProfile(
        token,
        name,
        datas?.data?.valid ? beUserId : ""
      );

      console.log("this is updated data", datas);
      navigate("/joinclan");
      if (data) {
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  const stopCharge = (energy) => {
    const token = sessionStorage.getItem("token");
    updateProfile(token, points, energy, false);
    setIsCharging(false);
    clearInterval(interval);
    interval = "";
  };

  const [isEnglish, setIsEnglish] = useState(false);

  useEffect(() => {
    const savedLanguage = sessionStorage.getItem("isEnglish");

    if (savedLanguage === null) {
      setIsEnglish(true);
      sessionStorage.setItem("isEnglish", true);
    } else {
      if (savedLanguage) {
        setIsEnglish(true);
      } else {
        setIsEnglish(false);
      }
    }

    const manageHomePage = async () => {
      const notificationData = localStorage.getItem("notification");
      const userId =
        new URLSearchParams(window.location.search).get("userId") || "";
      const userName =
        new URLSearchParams(window.location.search).get("userName") || "";
      setUserName(userName);
      if (notificationData) {
        setNotification(notificationData);
        setNotificationVisibile(true);
        localStorage.removeItem("notification");

        console.log("Notification set:", notificationData);

        setTimeout(() => {
          setNotificationVisibile(false);
          setTimeout(() => setNotification(""), 3000);
        }, 2000);
      }
      const token = sessionStorage.getItem("token");
      if (token) {
        const data = await getProfile(token);
        setPoints(data.user.score);
        setLevel(data.user.level);
        setEnergyCount(data.user.energyCount);
        setInitialPointsIncrement(data.user.multiTapLevel);
        setPointsIncrement(data.user.multiTapLevel);
        setFlyingTextContent(`+${data.user.multiTapLevel}`);
        setMaxEnergyCount(data.user.maxEnergyCount);
        setIsLoading(false);
        if (data.user.isCharging) {
          setIsCharging(data.user.isCharging);
          setRemainingTimeForNextCharge(data.remainTime);
          // alert(data.remainTime);
        }
        if (data.user.clan) {
          // setClan('669fae1c36b3b2880ae91976');
          setClan(data.user.clan);
          const clanData = await getClan(data.user.clan, token);
          setClanInfo(clanData);
          if (clanData.tapBonus > 0) {
            setInitialPointsIncrement(data.user.multiTapLevel + 1);
            setPointsIncrement(data.user.multiTapLevel + 1);
            setFlyingTextContent(`+${data.user.multiTapLevel + 1}`);
          }
          sessionStorage.setItem("clanId", data.user.clan);
        } else {
          setClan(null);
          setClanInfo({});
          sessionStorage.removeItem("clanId");
        }

        console.log(data.user.friends);
        sessionStorage.setItem("points", data.user.score);
        sessionStorage.setItem("level", data.user.level);
        sessionStorage.setItem(
          "normalReferralCount",
          data.user.normalReferralCount
        );
        sessionStorage.setItem(
          "premiumReferralCount",
          data.user.premiumReferralCount
        );
        sessionStorage.setItem("multiTapLevel", data.user.multiTapLevel);
        sessionStorage.setItem(
          "dailyAttemptsLevel",
          data.user.dailyAttemptsLevel
        );

        sessionStorage.setItem("exchangeId", data.user.exchange);

        if (data?.user?.isUpdated) {
          navigate("/joinclan");
        }
      } else {
        const userId =
          new URLSearchParams(window.location.search).get("userId") || "";
        const userName =
          new URLSearchParams(window.location.search).get("userName") || "";
        sessionStorage.setItem("userName", userName);

        if (userId) {
          const registerResponse = await register(userId, userName);
          setPoints(registerResponse.user.score);
          setLevel(registerResponse.user.level);
          setMaxEnergyCount(registerResponse.user.maxEnergyCount);
          setEnergyCount(registerResponse.user.energyCount);
          setInitialPointsIncrement(registerResponse.user.multiTapLevel);
          setPointsIncrement(registerResponse.user.multiTapLevel);
          setFlyingTextContent(`+${registerResponse.user.multiTapLevel}`);
          setIsCharging(registerResponse.user.isCharging);
          if (registerResponse.user.isCharging) {
            setRemainingTimeForNextCharge(registerResponse.remainTime);
            // alert(registerResponse.remainTime);
          }
          if (!isEmpty(registerResponse.user.clan)) {
            setClan(registerResponse.user.clan);
            const clanData = await getClan(
              registerResponse.user.clan,
              registerResponse.token
            );
            setClanInfo(clanData);
            if (clanData.tapBonus > 0) {
              setInitialPointsIncrement(
                registerResponse.user.multiTapLevel + 1
              );
              setPointsIncrement(registerResponse.user.multiTapLevel + 1);
              setFlyingTextContent(
                `+${registerResponse.user.multiTapLevel + 1}`
              );
            }
            sessionStorage.setItem("clanId", registerResponse.user.clan);
          } else {
            setClan(null);
            setClanInfo({});
            sessionStorage.removeItem("clanId");
          }
          setIsLoading(false);
          sessionStorage.setItem("token", registerResponse.token);
          sessionStorage.setItem("points", registerResponse.user.score);
          sessionStorage.setItem("level", registerResponse.user.level);
          sessionStorage.setItem("chatId", userId);
          sessionStorage.setItem(
            "normalReferralCount",
            registerResponse.user.normalReferralCount
          );
          sessionStorage.setItem(
            "premiumReferralCount",
            registerResponse.user.premiumReferralCount
          );
          sessionStorage.setItem(
            "multiTapLevel",
            registerResponse.user.multiTapLevel
          );
          sessionStorage.setItem(
            "dailyAttemptsLevel",
            registerResponse.user.dailyAttemptsLevel
          );
          sessionStorage.setItem("exchangeId", registerResponse.user.exchange);
          if (registerResponse?.user?.isUpdated) {
            navigate("/joinclan");
          }
        } else {
          if (
            window.Telegram &&
            window.Telegram.WebApp &&
            window.Telegram.WebApp.initData
          ) {
            const params = new URLSearchParams(window.Telegram.WebApp.initData);
            const user = JSON.parse(decodeURIComponent(params.get("user")));
            const registerResponse = await register(user.id, user.username);
            setPoints(registerResponse.user.score);
            setMaxEnergyCount(registerResponse.user.maxEnergyCount);
            setLevel(registerResponse.user.level);
            setEnergyCount(registerResponse.user.energyCount);
            setInitialPointsIncrement(registerResponse.user.multiTapLevel);
            setPointsIncrement(registerResponse.user.multiTapLevel);
            setFlyingTextContent(`+${registerResponse.user.multiTapLevel}`);
            setIsCharging(registerResponse.user.isCharging);
            if (registerResponse.user.isCharging) {
              setRemainingTimeForNextCharge(registerResponse.remainTime);
              // alert(registerResponse.remainTime);
            }
            if (!isEmpty(registerResponse.user.clan)) {
              setClan(registerResponse.user.clan);
              const clanData = await getClan(
                registerResponse.user.clan,
                registerResponse.token
              );
              setClanInfo(clanData);
              if (clanData.tapBonus > 0) {
                setInitialPointsIncrement(
                  registerResponse.user.multiTapLevel + 1
                );
                setPointsIncrement(registerResponse.user.multiTapLevel + 1);
                setFlyingTextContent(
                  `+${registerResponse.user.multiTapLevel + 1}`
                );
              }
              sessionStorage.setItem("clanId", registerResponse.user.clan);
            } else {
              setClan(null);
              setClanInfo({});
              sessionStorage.removeItem("clanId");
            }
            setIsLoading(false);
            sessionStorage.setItem("token", registerResponse.token);
            sessionStorage.setItem("points", registerResponse.user.score);
            sessionStorage.setItem("level", registerResponse.user.level);
            sessionStorage.setItem("chatId", user.id);
            sessionStorage.setItem(
              "normalReferralCount",
              registerResponse.user.normalReferralCount
            );
            sessionStorage.setItem(
              "premiumReferralCount",
              registerResponse.user.premiumReferralCount
            );
            sessionStorage.setItem(
              "multiTapLevel",
              registerResponse.user.multiTapLevel
            );
            sessionStorage.setItem(
              "dailyAttemptsLevel",
              registerResponse.user.dailyAttemptsLevel
            );
            sessionStorage.setItem(
              "exchangeId",
              registerResponse.user.exchange
            );
            if (registerResponse?.user?.isUpdated) {
              navigate("/joinclan");
            }
          } else {
            console.error("Telegram WebApp API is not available.");
          }
        }
      }
    };
    manageHomePage();
    // let inc = sessionStorage.getItem('multiTapLevel');
    // let total = sessionStorage.getItem('points');
    // if(points == NaN){
    //     setPointsIncrement(inc);
    //     setFlyingText(`+${inc}`);
    //     setPoints(total);
    // }
  }, []);

  const validateCustomer = async (customerId) => {
    const url = `https://api.fastshoppingstore.com/points-exchange/validate-customer?customerId=${customerId}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "X-API-Key":
            "MJelS1yGLXabynwZg2u9XVmCGQcuFn42GM9KrvZ4hYeaAPnHpEWHE8fXGl7lg4QS",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Response Data:", data);
      return data;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Layout>
      {isLoading ? (
        <div className="loading-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <div style={{ marginTop: "67px" }}>
          <div className="bg-img">
            <div
              style={{
                background:
                  "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 46.52%)",
                paddingTop: "280px",
              }}
            >
              <div className="profile__main">
                <h1 className="profile__main-head">
                  {" "}
                  {isEnglish ? "Set Up Your Profile" : "Imposta il tuo profilo"}
                </h1>
                <h1 className="profile__main-head-gradient">
                  {isEnglish ? "Set Up Your Profile" : "Imposta il tuo profilo"}
                </h1>
              </div>
              <div className="profile__input">
                <div className="profile__input-txt">
                  <label>
                    {isEnglish ? "Telegram Username" : "Nome utente Telegram"}
                  </label>
                  <input
                    placeholder="@BEUser"
                    value={userName}
                    readOnly
                    // onChange={(e) => setTelegramUsername(e.target.value)}
                  />
                </div>
                <div className="profile__input-txt">
                  <label>
                    {isEnglish ? "Name" : "Nome"}{" "}
                    <span style={{ color: "#C80C00" }}>*</span>
                  </label>
                  <input
                    placeholder={isEnglish ? "Enter Name" : "Inserisci il nome"}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="profile__input-txt">
                  <label>
                    {isEnglish ? "Customer ID" : "ID Cliente"}
                    <span
                      style={{
                        color: "#FFFFFF",
                        fontSize: "12px",
                        fontWeight: "500",
                        opacity: "30%",
                        background: "transparent",
                        backgroundClip: "unset",
                      }}
                    >
                      {isEnglish ? "(Optional)" : "(Opzionale)"}
                    </span>
                  </label>
                  <input
                    placeholder={
                      isEnglish
                        ? "Please Enter ID"
                        : "Si prega di inserire l'ID"
                    }
                    value={beUserId}
                    onChange={(e) => setBeUserId(e.target.value)}
                  />
                </div>
                {/* Localization Toogle button is here */}

                <div className="profile__btn">
                  <button
                    disabled={updateLoader ? true : false}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => setUpUser()}
                  >
                    <p> {isEnglish ? "Set Up" : "Configurazione"}</p>
                    {/* {updateLoader && <CircularProgress />} */}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* <NavBar /> */}
    </Layout>
  );
};

export default Profile;
