import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllClans } from "../api/getAllClans";
import { sendTelegramMessage } from "../api/sendTelegramMessage";
import { getMedalImage } from "../utils/getMedalImage";
import { debounce } from "../utils/debounce";
import { handleInvite } from "../utils/handleInvite";
import { formatUserCount } from "../utils/formatUserCount";

import { Switch } from "@mui/material";

import Layout from "../components/Layout/Layout";
import NavBar from "../components/NavBar/NavBar";
// import becoin from "../assets/becoin.png";
import arrowright from "../assets/arrowright.svg";
import userIcon from "../assets/userIcon.png";
import energyIcon from "../assets/energy.png";
import closeIcon from "../assets/closeIcon.png";
import astronaut from "../assets/astronaut.png";
import multiTapIcon from "../assets/multiTapIcon.png";
import be from "../assets/be.svg";
import "./JoinClan.css";
import { getProfile } from "../api/getProfile";
import { register } from "../api/register";
import { getClan } from "../api/getClan";
import { isEmpty } from "../utils/isEmpty";
import Header from "../components/Header/Header";

const initialCoinSize = 1;
const shrinkRate = 0.005;
const growIncrement = 0.017;
const coinScaleLevel1 = 1.19;
const coinScaleLevel2 = 1.44;
const coinScaleLevel3 = 1.71;
const maxCoinScale = 2;
const maxTime = 30;
const debounceDelay = 800;

const JoinClan = () => {
  const token = sessionStorage.getItem("token");
  const chatId = sessionStorage.getItem("chatId");
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const [data, setData] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [confirmRedeem, setConfirmRedeem] = useState(false);

  const [points, setPoints] = useState(0);
  const [level, setLevel] = useState(0);

  const [coinScale, setCoinScale] = useState(initialCoinSize);
  const [isVibrating, setIsVibrating] = useState(false);
  const [energyCount, setEnergyCount] = useState(0);
  const [energyPercentage, setEnergyPercentage] = useState(100);
  const [showEnergyModal, setShowEnergyModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [remainingTime, setRemainingTime] = useState(null);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDebouncing, setIsDebouncing] = useState(false);
  const [flyingText, setFlyingText] = useState([]);
  const [flyingTextContent, setFlyingTextContent] = useState("");
  const [initialPointsIncrement, setInitialPointsIncrement] = useState(null);
  const [pointsIncrement, setPointsIncrement] = useState(1);
  const [notification, setNotification] = useState("");
  const [notificationVisibile, setNotificationVisibile] = useState(false);
  const [clan, setClan] = useState(null);
  const [clanInfo, setClanInfo] = useState({});
  const [userName, setUserName] = useState();
  const [remainingTimeForNextCharge, setRemainingTimeForNextCharge] = useState(
    30 * 60
  );
  const [maxEnergyCount, setMaxEnergyCount] = useState(0);
  const [isCharging, setIsCharging] = useState(false);
  const [isEnglish, setIsEnglish] = useState(false);
  const [checked, setChecked] = useState(false);

  const navBarRef = useRef(); //Reference for navbar Function access

  useEffect(() => {
    const savedLanguage = sessionStorage.getItem("isEnglish");
    console.log(savedLanguage);
    if (savedLanguage === "true") {
      setIsEnglish(true);
      setChecked(true);
    } else {
      setIsEnglish(false);
      // setChecked(true);
    }

    const getClansInfo = async () => {
      const allClansData = await getAllClans(token);
      setData(allClansData);
    };

    getClansInfo();
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    if (dropdownVisible) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [dropdownVisible, setDropdownVisible]);

  // Handle the localization
  const handleChecked = (e) => {
    const isChecked = e.target.checked; // Get the updated value directly from the event
    setChecked(isChecked);

    console.log(isChecked); // Log the current value
    sessionStorage.setItem("isEnglish", isChecked); // Save the updated value in sessionStorage
    setIsEnglish(isChecked);
    console.log("English", isChecked); // Log the updated value

    // Now invoke the Navbar Function to change language
    if (navBarRef.current) {
      navBarRef.current.updateNavLanguagePref(); // Invoke the child function
    }
  };

  const handleClick = (id) => {
    navigate(`/community/${id}`);
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const closeDropdown = () => {
    setDropdownVisible(false);
  };

  const createClan = async () => {
    const token = sessionStorage.getItem("token");
    const chatId = sessionStorage.getItem("chatId");

    if (token) {
      setIsLoading(true);
      const data = await getProfile(token);
      if (data?.user?.score) {
        console.log("this is user data", data);
        if (data?.user?.score < 50000) {
          setIsLoading(false);
          setDropdownVisible(true);
        } else {
          const message = "Please send me the command: /createClan";
          console.log("these arw chat id", chatId, message);
          await sendTelegramMessage(chatId, message);
          setIsLoading(false);
          if (
            window.Telegram &&
            window.Telegram.WebApp &&
            window.Telegram.WebApp.close
          ) {
            window.Telegram.WebApp.close();
          } else {
            console.error("Telegram WebApp API is not available.");
          }
        }
      }
    }
    // const points = parseInt(sessionStorage.getItem("points"), 10);
    // if (points < 50000) {
    //   localStorage.setItem("notification", "Not enough coins");
    //   navigate(`/`);
    // } else {
    //   const message = "Please send me the command: /createClan";
    //   await sendTelegramMessage(chatId, message);
    //   if (
    //     window.Telegram &&
    //     window.Telegram.WebApp &&
    //     window.Telegram.WebApp.close
    //   ) {
    //     window.Telegram.WebApp.close();
    //   } else {
    //     console.error("Telegram WebApp API is not available.");
    //   }
    // }
  };

  const debouncedCreateClan = debounce(createClan, 300);

  const handleInviteClick = async () => {
    const chatId = sessionStorage.getItem("chatId");
    const userName = sessionStorage.getItem("userName");
    setIsLoading(true);

    await handleInvite(chatId, userName);

    setIsLoading(false);
    if (
      window.Telegram &&
      window.Telegram.WebApp &&
      window.Telegram.WebApp.close
    ) {
      window.Telegram.WebApp.close();
    } else {
      console.error("Telegram WebApp API is not available.");
    }
  };

  useEffect(() => {
    const manageHomePage = async () => {
      const notificationData = localStorage.getItem("notification");
      const langPref = sessionStorage.getItem("isEnglish");

      if (!langPref) {
        // Tada
        sessionStorage.setItem("isEnglish", true);
        setIsEnglish(true);
      }
      const userId =
        new URLSearchParams(window.location.search).get("userId") || "";
      const userName =
        new URLSearchParams(window.location.search).get("userName") || "";
      setUserName(userName);
      if (notificationData) {
        setNotification(notificationData);
        setNotificationVisibile(true);
        localStorage.removeItem("notification");

        console.log("Notification set:", notificationData);

        setTimeout(() => {
          setNotificationVisibile(false);
          setTimeout(() => setNotification(""), 3000);
        }, 2000);
      }
      const token = sessionStorage.getItem("token");
      if (token) {
        const data = await getProfile(token);
        setPoints(data.user.score);
        setLevel(data.user.level);
        setEnergyCount(data.user.energyCount);
        setInitialPointsIncrement(data.user.multiTapLevel);
        setPointsIncrement(data.user.multiTapLevel);
        setFlyingTextContent(`+${data.user.multiTapLevel}`);
        setMaxEnergyCount(data.user.maxEnergyCount);

        const allClansData = await getAllClans(token);
        setData(allClansData);

        if (data.user.isCharging) {
          setIsCharging(data.user.isCharging);
          setRemainingTimeForNextCharge(data.remainTime);
          // alert(data.remainTime);
        }
        if (data.user.clan) {
          // setClan('669fae1c36b3b2880ae91976');
          setClan(data.user.clan);
          const clanData = await getClan(data.user.clan, token);
          setClanInfo(clanData);
          if (clanData.tapBonus > 0) {
            setInitialPointsIncrement(data.user.multiTapLevel + 1);
            setPointsIncrement(data.user.multiTapLevel + 1);
            setFlyingTextContent(`+${data.user.multiTapLevel + 1}`);
          }
          sessionStorage.setItem("clanId", data.user.clan);
        } else {
          setClan(null);
          setClanInfo({});
          sessionStorage.removeItem("clanId");
        }
        setIsLoading(false);
        console.log(data.user.friends);
        sessionStorage.setItem("points", data.user.score);
        sessionStorage.setItem("level", data.user.level);
        sessionStorage.setItem(
          "normalReferralCount",
          data.user.normalReferralCount
        );
        sessionStorage.setItem(
          "premiumReferralCount",
          data.user.premiumReferralCount
        );
        sessionStorage.setItem("multiTapLevel", data.user.multiTapLevel);
        sessionStorage.setItem(
          "dailyAttemptsLevel",
          data.user.dailyAttemptsLevel
        );
        sessionStorage.setItem("exchangeId", data.user.exchange);
      } else {
        const userId =
          new URLSearchParams(window.location.search).get("userId") || "";
        const userName =
          new URLSearchParams(window.location.search).get("userName") || "";
        sessionStorage.setItem("userName", userName);

        if (userId) {
          const registerResponse = await register(userId, userName);
          setPoints(registerResponse.user.score);
          setLevel(registerResponse.user.level);
          setMaxEnergyCount(registerResponse.user.maxEnergyCount);
          setEnergyCount(registerResponse.user.energyCount);
          setInitialPointsIncrement(registerResponse.user.multiTapLevel);
          setPointsIncrement(registerResponse.user.multiTapLevel);
          setFlyingTextContent(`+${registerResponse.user.multiTapLevel}`);
          setIsCharging(registerResponse.user.isCharging);
          if (registerResponse.user.isCharging) {
            setRemainingTimeForNextCharge(registerResponse.remainTime);
            // alert(registerResponse.remainTime);
          }
          if (!isEmpty(registerResponse.user.clan)) {
            setClan(registerResponse.user.clan);
            const clanData = await getClan(
              registerResponse.user.clan,
              registerResponse.token
            );
            setClanInfo(clanData);
            if (clanData.tapBonus > 0) {
              setInitialPointsIncrement(
                registerResponse.user.multiTapLevel + 1
              );
              setPointsIncrement(registerResponse.user.multiTapLevel + 1);
              setFlyingTextContent(
                `+${registerResponse.user.multiTapLevel + 1}`
              );
            }
            sessionStorage.setItem("clanId", registerResponse.user.clan);
          } else {
            setClan(null);
            setClanInfo({});
            sessionStorage.removeItem("clanId");
          }
          setIsLoading(false);
          sessionStorage.setItem("token", registerResponse.token);
          const allClansData = await getAllClans(registerResponse.token);
          setData(allClansData);

          sessionStorage.setItem("points", registerResponse.user.score);
          sessionStorage.setItem("level", registerResponse.user.level);
          sessionStorage.setItem("chatId", userId);
          sessionStorage.setItem(
            "normalReferralCount",
            registerResponse.user.normalReferralCount
          );
          sessionStorage.setItem(
            "premiumReferralCount",
            registerResponse.user.premiumReferralCount
          );
          sessionStorage.setItem(
            "multiTapLevel",
            registerResponse.user.multiTapLevel
          );
          sessionStorage.setItem(
            "dailyAttemptsLevel",
            registerResponse.user.dailyAttemptsLevel
          );
          sessionStorage.setItem("exchangeId", registerResponse.user.exchange);
        } else {
          if (
            window.Telegram &&
            window.Telegram.WebApp &&
            window.Telegram.WebApp.initData
          ) {
            const params = new URLSearchParams(window.Telegram.WebApp.initData);
            const user = JSON.parse(decodeURIComponent(params.get("user")));
            const registerResponse = await register(user.id, user.username);
            setPoints(registerResponse.user.score);
            setMaxEnergyCount(registerResponse.user.maxEnergyCount);
            setLevel(registerResponse.user.level);
            setEnergyCount(registerResponse.user.energyCount);
            setInitialPointsIncrement(registerResponse.user.multiTapLevel);
            setPointsIncrement(registerResponse.user.multiTapLevel);
            setFlyingTextContent(`+${registerResponse.user.multiTapLevel}`);
            setIsCharging(registerResponse.user.isCharging);
            if (registerResponse.user.isCharging) {
              setRemainingTimeForNextCharge(registerResponse.remainTime);
              // alert(registerResponse.remainTime);
            }
            if (!isEmpty(registerResponse.user.clan)) {
              setClan(registerResponse.user.clan);
              const clanData = await getClan(
                registerResponse.user.clan,
                registerResponse.token
              );
              setClanInfo(clanData);
              if (clanData.tapBonus > 0) {
                setInitialPointsIncrement(
                  registerResponse.user.multiTapLevel + 1
                );
                setPointsIncrement(registerResponse.user.multiTapLevel + 1);
                setFlyingTextContent(
                  `+${registerResponse.user.multiTapLevel + 1}`
                );
              }
              sessionStorage.setItem("clanId", registerResponse.user.clan);
            } else {
              setClan(null);
              setClanInfo({});
              sessionStorage.removeItem("clanId");
            }
            setIsLoading(false);
            sessionStorage.setItem("token", registerResponse.token);
            const allClansData = await getAllClans(registerResponse.token);
            setData(allClansData);
            sessionStorage.setItem("points", registerResponse.user.score);
            sessionStorage.setItem("level", registerResponse.user.level);
            sessionStorage.setItem("chatId", user.id);
            sessionStorage.setItem(
              "normalReferralCount",
              registerResponse.user.normalReferralCount
            );
            sessionStorage.setItem(
              "premiumReferralCount",
              registerResponse.user.premiumReferralCount
            );
            sessionStorage.setItem(
              "multiTapLevel",
              registerResponse.user.multiTapLevel
            );
            sessionStorage.setItem(
              "dailyAttemptsLevel",
              registerResponse.user.dailyAttemptsLevel
            );
            sessionStorage.setItem(
              "exchangeId",
              registerResponse.user.exchange
            );
          } else {
            console.error("Telegram WebApp API is not available.");
          }
        }
      }
    };
    manageHomePage();
    // let inc = sessionStorage.getItem('multiTapLevel');
    // let total = sessionStorage.getItem('points');
    // if(points == NaN){
    //     setPointsIncrement(inc);
    //     setFlyingText(`+${inc}`);
    //     setPoints(total);
    // }
  }, []);

  useEffect(() => {
    if (dropdownVisible) {
      // Disable background scrolling by setting body overflow to hidden
      document.body.style.overflow = "hidden";
    } else {
      // Re-enable scrolling when the dropdown is not visible
      document.body.style.overflow = "auto";
    }

    // Cleanup function to restore scrolling when the component unmounts or dropdownVisible changes
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [dropdownVisible]);
  useEffect(() => {
    if (confirmRedeem) {
      // Disable background scrolling by setting body overflow to hidden
      document.body.style.overflow = "hidden";
    } else {
      // Re-enable scrolling when the dropdown is not visible
      document.body.style.overflow = "auto";
    }

    // Cleanup function to restore scrolling when the component unmounts or dropdownVisible changes
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [confirmRedeem]);
  return (
    // remove
    <Layout>
      {isLoading ? (
        <div className="loading-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <div style={{ marginTop: "67px" }}>
          {/* <Header /> */}
          <div
            style={{
              backgroundImage: "url(/Images/Profile/boys.png)",
              backgroundPosition: "top center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "350px 320px",
            }}
          >
            <div
              style={{
                background:
                  "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 46.52%)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  padding: "300px  35px 150px",
                  //   paddingBottom: "150px",
                }}
                className="joinClan__main"
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    paddingInline: "1rem",
                    // height: "500px",
                    alignItems: "end",
                    justifyContent: "center",
                    paddingLeft: "25px",
                    paddingRight: "25px",
                  }}
                >
                  <div className="joinclan-textdiv">
                    <h1 className="joinclan__main-head-gradient">Orbitz</h1>
                    <h1 className="joinclan__main-head">Orbitz</h1>
                  </div>
                </div>
                <div className="joinclan_top2">
                  <div className="joinclan_top2-button-container">
                    <button
                      onClick={handleInviteClick}
                      className="joinclan_top2-button"
                    >
                      {isEnglish ? "Invite a Friend" : "Invita un Amico"}
                    </button>
                    <button
                      onClick={() => {
                        navigate("/earn");
                      }}
                      className="joinclan_top2-button"
                    >
                      {isEnglish ? "Earn Points" : "Guadagna Punti"}
                    </button>
                  </div>

                  {/* <div className="joinclan_top2-1">
                    <p className="joinclan_top2-1-p">
                      Join a clan, and compete with others!
                    </p>
                  </div>
                  <div
                    class="ether-top3-2-card2-d2"
                    onClick={() => setConfirmRedeem(true)}
                  >
                    <p className="joinClanText">Create Clan</p>
         
                    <img src={arrowright} alt="/"  />
                  
                  </div> */}
                </div>

                {/* Localization Btn */}

                <div
                  className="profile__input-txt"
                  style={{ marginTop: "45px" }}
                >
                  <label>
                    {isEnglish ? "Language" : "Lingua"}
                    <span
                      style={{
                        color: "#FFFFFF",
                        fontSize: "12px",
                        fontWeight: "500",
                        opacity: "30%",
                        background: "transparent",
                        backgroundClip: "unset",
                      }}
                    ></span>
                  </label>
                  <div className="LocalizationToogl_Sectipon">
                    <div className="LocalizationText">
                      {isEnglish ? "English" : "Italian"}
                    </div>
                    {/* Switch Btn here */}

                    <Switch
                      onChange={(event) => handleChecked(event)}
                      checked={checked}
                      sx={{
                        ml: 1,
                        "& .MuiSwitch-thumb": {
                          width: "10px", // Increase width of the thumb
                          height: "10px", // Increase height of the thumb
                          color: "#000000",
                        },

                        "& .MuiSwitch-switchBase": {
                          padding: 1, // Adjust padding if needed
                          bottom: 0,
                          left: "7px",
                          top: "1px",

                          "&.Mui-checked": {
                            transform: "translateX(10px)", // Move the thumb to the right when checked
                            color: "#000000", // Thumb color when checked

                            "& + .MuiSwitch-track": {
                              backgroundColor: "transparent", // Ensure transparency when checked
                              backgroundImage:
                                "linear-gradient(92.23deg, #FF00D9 -19.69%, #6C4EFE 100%)",
                              opacity: 1,
                            },
                          },
                        },
                        "& .MuiSwitch-track": {
                          width: "27px",
                          height: "15px",
                          borderRadius: "100px !important",
                          bgcolor: "transparent",
                          backgroundColor: "transparent", // Remove background color of track
                          boxShadow: "none", // Remove shadow if any
                          backgroundImage:
                            "linear-gradient(92.23deg, #FF00D9 -19.69%, #6C4EFE 100%)", // Apply your gradient
                          opacity: 1, // Ensure full opacity (MUI may apply a default opacity)
                        },
                      }}
                    />
                  </div>
                </div>

                {/* <h4 className="joinclan__head">Join Clan</h4>
                  <div style={{}} className="clans__data">
                    {data && Array.isArray(data) && data.length > 0 ? (
                      data.map((item) => (
                        <div
                          key={item?._id}
                          // onClick={() => handleClick(item?._id)}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            justifyContent: "space-between",
                            cursor: "pointer",
                            background: "#0E0E0E",
                            borderRadius: "15px",
                            padding: "12px 15px",
                          }}
                        >
                          <div
                            style={{
                              position: "relative",
                              display: "flex",
                              flexDirection: "column",
                          
                            }}
                          >
                            <img
                              src={item?.profilePictureUrl}
                              alt="Avatar"
                              style={{
                                width: "30px",
                                height: "30px",
                                position: "relative",
                                borderRadius: "50%",
                                zIndex: 0,
                              }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginLeft: "10px",
                              gap: "0.3125rem",
                              width: "100%",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "MontserratBold",
                                fontWeight: "800",
                                fontSize: "16px",
                                color: "#F7F9FB",
                                fontStyle: "italic",
                                lineHeight: "24px",
                              }}
                              className="joinClan__main-ClanName"
                            >
                              {item?.clanName}
                            </span>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <span
                                style={{
                                  fontFamily: "Montserrat",
                                  fontWeight: "500",
                                  fontSize: "1rem",
                                  color: "white",
                                  opacity: "0.6",
                                }}
                              >
                                {formatUserCount(item.userCount)}
                                {item.userCount > 1000 && "+"}
                              </span>
                              {item?.energyBonus > 0 && (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  
                                  }}
                                >
                              
                                  <span
                                    style={{
                                      fontFamily: "Montserrat",
                                      fontWeight: "500",
                                      fontSize: "12px",
                                      color: "white",
                                    
                                    }}
                                  >
                                    +{item?.energyBonus}
                                  </span>
                                </div>
                              )}
                              {item.tapBonus > 0 && (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    
                                  }}
                                >
                                  
                                  <span
                                    style={{
                                      fontFamily: "Montserrat",
                                      fontWeight: "400",
                                      fontSize: "1rem",
                                      color: "white",
                                      marginLeft: "0.25rem",
                                    }}
                                  >
                                    +{item?.tapBonus}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                          {item.ranking > 3 ? (
                            <span
                              style={{
                                fontFamily: "MontserratBold",
                                fontWeight: "700",
                                fontSize: "1rem",
                                color: "transparent",
                                background:
                                  "linear-gradient(90.24deg, #DCE64F 0.06%, #F8FF96 43.96%)",
                                backgroundClip: "text",
                                WebkitBackgroundClip: "text",
                                marginLeft: "1.875rem",
                              }}
                            >
                              {item?.ranking}
                            </span>
                          ) : (
                            <img
                              src={getMedalImage(item?.ranking)}
                              alt="Gold Medal"
                              style={{
                                width: "30px",
                                height: "30px",
                          
                              }}
                            />
                          )}
                        </div>
                      ))
                    ) : (
                      <p className="bottomtext">No clans available.</p>
                    )}
                  </div> */}
              </div>
            </div>
          </div>
          {/* <div style={{ display: "flex", justifyContent: "center" }}>
            {dropdownVisible && (
              <>
                <div
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.1)", // Black background with 10% opacity
                    backdropFilter: "blur(5px)", // Backdrop blur effect
                    WebkitBackdropFilter: "blur(5px)", // Backdrop blur effect for Safari
                    zIndex: 998, // Behind the dropdown
                  }}
                ></div>
                <div
                  ref={dropdownRef}
                  style={{
                    width: "90%",
                    borderRadius: "1.25rem 1.25rem 1.25rem 1.25rem",
                    background: "rgba(140,140,140,0.1)",
                    backdropFilter: "blur(3rem)",
                    WebkitBackdropFilter: "blur(3rem)",
                    position: "fixed",
                    bottom: "25%",
                    display: "flex",
                    flexDirection: "column",
                    padding: "32px 43px",
                    alignItems: "center",
                    gap: "25px",
                    zIndex: "999",
                  }}
                >
                  <button
                    style={{
                      position: "absolute",
                      top: "1rem",
                      right: "1rem",
                      float: "right",
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                    onClick={closeDropdown}
                  >
                    <img
                      src={closeIcon}
                      alt="Close Icon"
                      style={{ width: "1.875rem", height: "1.875rem" }}
                    />
                  </button>
                  <img
                    src={astronaut}
                    alt="Astronaut"
                    style={{ width: "100px", height: "100px" }}
                  />
                  <span
                    style={{
                      // marginTop: "0rem",
                      fontFamily: "MontserratBold",
                      fontWeight: "800",
                      fontSize: "36px",
                      color: "white",
                      lineHeight: "100%",
                      textAlign: "center",
                      fontStyle: "italic",
                    }}
                  >
                    Create Clan
                  </span>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        // marginTop: "0.5625rem",
                        fontFamily: "Montserrat",
                        fontWeight: "600",
                        fontSize: "16px",
                        color: "#F1F1F1",
                        lineHeight: "24px",
                        textAlign: "center",
                      }}
                    >
                      Invite Friends to your Commuity & Earn Moon Coins even
                      faster
                    </span>
                  </div>
                  <div style={{ marginTop: "0.5625rem" }}>
                    <span
                      style={{
                        // marginRight: "0.25rem",
                        fontFamily: "MontserratBold",
                        fontWeight: "800",
                        fontSize: "28px",
                        color: "white",
                        fontStyle: "italic",
                      }}
                    >
                      500,000
                    </span>
                    <span
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                        fontSize: "1.125rem",
                        color: "#F1F1F1",
                        opacity: "0.6",
                      }}
                    >
                      / per clan
                    </span>
                  </div>
                  <button
                    style={{
                      // marginTop: "1.875rem",
                      borderRadius: "18.75rem",
                      background:
                        "linear-gradient(92.23deg, #FF00D9 -19.69%, #6C4EFE 100%)",
                      height: "60px",
                      width: "100%",
                      paddingBlock: "0.125rem",
                      color: "white",
                      fontFamily: "Montserrat",
                      fontWeight: "600",
                      fontSize: "1.125rem",
                      cursor: "pointer",
                      border: "none",
                    }}
                  >
                    Get
                  </button>
                </div>
              </>
            )}
            {confirmRedeem && (
              <>
                <div
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                    backdropFilter: "blur(5px)",
                    WebkitBackdropFilter: "blur(5px)",
                    zIndex: 998,
                  }}
                ></div>
                <div
                  ref={dropdownRef}
                  style={{
                    width: "90%",
                    borderRadius: "1.25rem 1.25rem 1.25rem 1.25rem",
                    background: "#8C8C8C1A",
                    backdropFilter: "blur(3rem)",
                    WebkitBackdropFilter: "blur(3rem)",
                    position: "fixed",
                    bottom: "25%",
                    display: "flex",
                    flexDirection: "column",
                    padding: "40px 43px",
                    alignItems: "center",
                    zIndex: "999",
                  }}
                >
                  <button
                    style={{
                      position: "absolute",
                      top: "1rem",
                      right: "1rem",
                      float: "right",
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setConfirmRedeem(false);
                    }}
                  >
                    <img
                      src={closeIcon}
                      alt="Close Icon"
                      style={{ width: "1.875rem", height: "1.875rem" }}
                    />
                  </button>
                  <img
                    src="/Images/Header/boy.svg"
                    alt="/"
                    style={{
                      // width: "100px",
                      // height: "100px",
                      // paddingBottom: "25px",
                    }}
                  />
                  <span
                    style={{
                      fontFamily: "MontserratBold",
                      fontWeight: "800",
                      fontSize: "36px",
                      color: "white",
                      lineHeight: "24px",
                      fontStyle: "italic",
                      textAlign: "center",
                    }}
                    className="redeemUSDT__modal-h"
                  >
                    Coming Soon
                  </span>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: "600",
                        fontSize: "15px",
                        color: "#F1F1F1",
                        textAlign: "center",
                        lineHeight: "24px",
                        paddingTop: "25px",
                        paddingBottom: "25px",
                      }}
                      className="redeemUSDT__modal-p"
                    >
                      This feature is coming in the next version of Orbitz Mini
                      App.
                      <br />
                      Stay tuned!
                    </span>
                  </div>
                  <div className="redeemModal__buttons">
                    <button className="redeem__bottom-btn">
                      <p>Awesome</p>
                    </button>
                  </div>
                </div>
              </>
            )}
          </div> */}
        </div>
      )}
      <NavBar ref={navBarRef} />
    </Layout>
  );
};

export default JoinClan;
