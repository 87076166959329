import React from "react";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import NavBar from "../../components/NavBar/NavBar";
import Layout from "../../components/Layout/Layout";
import "./raffel.css";
import astronaut from "../../assets/astronaut.png";
import closeIcon from "../../assets/closeIcon.png";
import { getProfile } from "../../api/getProfile";
import { redeemUser } from "../../api/Redeem";
import { CircularProgress } from "@mui/material";

const Raffel = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [confirmRedeem, setConfirmRedeem] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const [isSelected, setIsSelected] = useState(false);
  const [beId, setbeId] = useState();
  const [totalPoints, setTotalPoints] = useState();
  const [userId, setUserId] = useState();
  const [updateLoader, setUpdateLoader] = useState(false);
  const [update, setUpdate] = useState();
  const [success, setSuccess] = useState(false);
  const [userPoints, setUserPoints] = useState();

  const handleBorder = () => {
    setIsSelected(true); // Toggle selected state on click
  };
  const closeDropdown = () => {
    setDropdownVisible(false);
  };
  const handleSuccess = () => {
    setSuccess(true);
  };
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    if (dropdownVisible) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [dropdownVisible, setDropdownVisible]);
  const toggleDropdown = async () => {
    console.log("this is redeem id", typeof beId);
    if (!beId || beId === "" || beId == 0) {
      setDropdownVisible(!dropdownVisible);
      return;
    }
    setConfirmRedeem(true);
  };
  useEffect(() => {
    if (dropdownVisible) {
      // Disable background scrolling by setting body overflow to hidden
      document.body.style.overflow = "hidden";
    } else {
      // Re-enable scrolling when the dropdown is not visible
      document.body.style.overflow = "auto";
    }

    // Cleanup function to restore scrolling when the component unmounts or dropdownVisible changes
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [dropdownVisible]);
  useEffect(() => {
    if (confirmRedeem) {
      // Disable background scrolling by setting body overflow to hidden
      document.body.style.overflow = "hidden";
    } else {
      // Re-enable scrolling when the dropdown is not visible
      document.body.style.overflow = "auto";
    }

    // Cleanup function to restore scrolling when the component unmounts or dropdownVisible changes
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [confirmRedeem]);
  useEffect(() => {
    if (success) {
      // Disable background scrolling by setting body overflow to hidden
      document.body.style.overflow = "hidden";
    } else {
      // Re-enable scrolling when the dropdown is not visible
      document.body.style.overflow = "auto";
    }

    // Cleanup function to restore scrolling when the component unmounts or dropdownVisible changes
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [success]);

  useEffect(() => {
    setIsloading(true);
    const token = sessionStorage.getItem("token");
    async function getData() {
      if (token) {
        const data = await getProfile(token);

        setbeId(data?.user?.beUserId);
        setTotalPoints(data?.user?.score);
        setUserId(data?.user?._id);
        setIsloading(false);
      }
    }
    getData();
  }, [update]);

  const handleSave = async () => {
    try {
      setIsloading(true);

      const dataOfRedeem = await redeemUser(
        userId,
        1 / userPoints,
        "Raffle",
        "",
        "",
        1
      );
      handleSuccess();
      setConfirmRedeem(false);
      setUpdate(dataOfRedeem);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const validateCustomer = async (customerId) => {
    const url = `https://api.fastshoppingstore.com/points-exchange/rate?currencyFrom=be-points&currencyTo=raffle`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "X-API-Key":
            "MJelS1yGLXabynwZg2u9XVmCGQcuFn42GM9KrvZ4hYeaAPnHpEWHE8fXGl7lg4QS",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      setUserPoints(data?.data?.rate);
      return data?.data?.rate;
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    validateCustomer();
  }, []);

  const [isEnglish, setIsEnglish] = useState(false);

  useEffect(() => {
    const savedLanguage = sessionStorage.getItem("isEnglish");
    if (savedLanguage === "true") {
      setIsEnglish(true);
    } else {
      setIsEnglish(false);
    }
  }, []);

  return (
    <Layout>
      {!isLoading ? (
        <div className="loading-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <>
          {/* <Header /> */}
          <div className="back-icon">
            <img
              src="/Images/back-arrow.svg"
              alt="back"
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
          <div className="topredeem">
            <div className="redeem__topbox">
              <p> {isEnglish ? "Available Points" : "Punti disponibili"}</p>
              <div className="redeem__topbox-points">
                <img
                  src="/Images/image.png"
                  alt="/"
                  style={{ width: "25px", height: "25px" }}
                />
                <p>
                  {/* {totalPoints ? totalPoints.toLocaleString() : 0} */}
                  {totalPoints ? totalPoints.toLocaleString() : 0}
                </p>
              </div>
              <div className="redeem__topbox-btn">
                <button
                  onClick={() => {
                    navigate("/earn");
                  }}
                  className="redeem__topbox-btn1"
                >
                  {isEnglish ? "Get Points" : "Ottieni Punti"}
                </button>
                <button
                  onClick={() => {
                    navigate("/history");
                  }}
                  className="redeem__topbox-btn2"
                >
                  {isEnglish ? "Points History" : "Cronologia Punti"}
                </button>
              </div>
            </div>
            <div className="cardscontainer">
              <p className="cardscontainer_text">
                {isEnglish ? "Live Exchange Rate=" : "Tasso di Cambio Attuale="}{" "}
                {1 / userPoints}:1
                <span className="cardscontainer_text-tic">
                  {isEnglish ? "Ticket" : "Biglietto"}
                </span>
              </p>
              <div className={`raffel_main`} onClick={handleBorder}>
                <img
                  src={
                    isEnglish
                      ? "/Images/redeem/raffel.svg"
                      : "/Images/redeem/raffelsitalian.svg"
                  }
                  alt="card"
                  className="raffelvard-image"
                />
                <div className="raffelcard_d1">
                  <p className="raffelcard_d1_text1">
                    {isEnglish ? "Points" : "Punti"}
                  </p>
                  <p className="raffelcard_d1_text1">{1 / userPoints}</p>
                </div>
              </div>
            </div>
            <div className="bottomButtondiv">
              <button
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                disabled={parseInt(totalPoints) > 1000 ? false : true}
                // disabled={
                //   !isSelected
                //     && false
                //     : parseInt(totalPoints) > 1000000
                //     ? false
                //     : true
                // }
                // disabled={totalPoints >= selectedCard?.value ? false : true}
                className="redeem__bottom-btn"
                onClick={toggleDropdown}
              >
                <p>{isEnglish ? "Redeem Now" : "Riscatta Ora"}</p>
                {/* {updateLoader && <CircularProgress />} */}
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {dropdownVisible && (
              <>
                <div
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.1)", // Black background with 10% opacity
                    backdropFilter: "blur(5px)", // Backdrop blur effect
                    WebkitBackdropFilter: "blur(5px)", // Backdrop blur effect for Safari
                    zIndex: 998, // Behind the dropdown
                  }}
                ></div>
                <div
                  ref={dropdownRef}
                  style={{
                    width: "90%",
                    borderRadius: "1.25rem 1.25rem 1.25rem 1.25rem",
                    background: "#8C8C8C1A",
                    backdropFilter: "blur(3rem)",
                    WebkitBackdropFilter: "blur(3rem)",
                    position: "fixed",
                    bottom: "25%",
                    display: "flex",
                    flexDirection: "column",
                    padding: "32px 35px",
                    alignItems: "center",
                    // gap: "25px",
                    zIndex: "999",
                  }}
                  className="redeemModal"
                >
                  <button
                    style={{
                      position: "absolute",
                      top: "1rem",
                      right: "1rem",
                      float: "right",
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                    onClick={closeDropdown}
                  >
                    <img
                      src={closeIcon}
                      alt="Close Icon"
                      style={{ width: "1.875rem", height: "1.875rem" }}
                    />
                  </button>
                  <img
                    src={astronaut}
                    alt="Astronaut"
                    style={{ width: "100px", height: "100px" }}
                  />
                  <span
                    style={{
                      // marginTop: "0.5625rem",
                      fontFamily: "MontserratBold",
                      fontWeight: "800",
                      fontSize: "36px",
                      color: "white",
                      lineHeight: "24px",
                      fontStyle: "italic",
                    }}
                    className="redeemModal__head"
                  >
                    Redeem
                  </span>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span
                      style={{
                        // marginTop: "0.5625rem",
                        fontFamily: "Montserrat",
                        fontWeight: "600",
                        fontSize: "16px",
                        color: "#F1F1F1",
                        textAlign: "center",
                        lineHeight: "24px",
                        paddingTop: "25px",
                        paddingBottom: "25px",
                      }}
                      className="redeemModal__text"
                    >
                      You don't have a Customer id attached, Please update your
                      profile or create a Orbitz account.
                    </span>
                    {/* <span
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "16px",
                  color: "#F1F1F1",
                }}
              >
                and Earn Moon Coins even faster
              </span> */}
                  </div>

                  <div className="redeemModal__buttons">
                    <button
                      onClick={() => {
                        navigate("/editProfile");
                      }}
                      className="redeemModal-btn1"
                    >
                      Update Profile
                    </button>
                    <button
                      onClick={() => {
                        window.location.href = "https://online-line.com/signup";
                      }}
                      className="redeemModal-btn2"
                    >
                      Signup to Orbitz
                    </button>
                  </div>
                </div>
              </>
            )}
            {confirmRedeem && (
              <>
                <div
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.1)", // Black background with 10% opacity
                    backdropFilter: "blur(5px)", // Backdrop blur effect
                    WebkitBackdropFilter: "blur(5px)", // Backdrop blur effect for Safari
                    zIndex: 998, // Behind the dropdown
                  }}
                ></div>
                <div
                  ref={dropdownRef}
                  style={{
                    width: "90%",
                    borderRadius: "1.25rem 1.25rem 1.25rem 1.25rem",
                    background: "#8C8C8C1A",
                    backdropFilter: "blur(3rem)",
                    WebkitBackdropFilter: "blur(3rem)",
                    position: "fixed",
                    bottom: "25%",
                    display: "flex",
                    flexDirection: "column",
                    padding: "32px 35px",
                    alignItems: "center",
                    // gap: "25px",
                    zIndex: "999",
                  }}
                  className="redeemModal"
                >
                  <button
                    style={{
                      position: "absolute",
                      top: "1rem",
                      right: "1rem",
                      float: "right",
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setConfirmRedeem(false);
                    }}
                  >
                    <img
                      src={closeIcon}
                      alt="Close Icon"
                      style={{ width: "1.875rem", height: "1.875rem" }}
                    />
                  </button>
                  <img
                    src={astronaut}
                    alt="Astronaut"
                    style={{ width: "100px", height: "100px" }}
                  />
                  <span
                    style={{
                      // marginTop: "0.5625rem",
                      fontFamily: "MontserratBold",
                      fontWeight: "800",
                      fontSize: "36px",
                      color: "white",
                      lineHeight: "24px",
                      fontStyle: "italic",
                    }}
                    className="redeemModal__head"
                  >
                    Redeem
                  </span>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span
                      style={{
                        // marginTop: "0.5625rem",
                        fontFamily: "Montserrat",
                        fontWeight: "600",
                        fontSize: "16px",
                        color: "#F1F1F1",
                        textAlign: "center",
                        lineHeight: "24px",
                        paddingTop: "25px",
                        paddingBottom: "25px",
                      }}
                      className="redeemModal__text"
                    >
                      Are you sure you want to redeem {1 / userPoints} Points to
                      Raffle Tickets?
                    </span>
                  </div>

                  <div className="redeemModal__buttons">
                    <button
                      onClick={() => {
                        setConfirmRedeem(false);
                      }}
                      className="redeem__bottom-btn2"
                    >
                      Go Back
                    </button>
                    <button
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        handleSave();
                      }}
                      className="redeem__bottom-btn3"
                    >
                      <p>Yes</p>
                      {updateLoader && <CircularProgress />}
                    </button>
                  </div>
                </div>
              </>
            )}
            {success && (
              <>
                <div
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.1)", // Black background with 10% opacity
                    backdropFilter: "blur(5px)", // Backdrop blur effect
                    WebkitBackdropFilter: "blur(5px)", // Backdrop blur effect for Safari
                    zIndex: 998, // Behind the dropdown
                  }}
                ></div>
                <div
                  ref={dropdownRef}
                  style={{
                    width: "90%",
                    borderRadius: "1.25rem 1.25rem 1.25rem 1.25rem",
                    background: "#8C8C8C1A",
                    backdropFilter: "blur(3rem)",
                    WebkitBackdropFilter: "blur(3rem)",
                    position: "fixed",
                    bottom: "25%",
                    display: "flex",
                    flexDirection: "column",
                    padding: "32px 35px",
                    alignItems: "center",
                    // gap: "25px",
                    zIndex: "999",
                  }}
                  className="redeemModal"
                >
                  <button
                    style={{
                      position: "absolute",
                      top: "1rem",
                      right: "1rem",
                      float: "right",
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setSuccess(false);
                    }}
                  >
                    <img
                      src={closeIcon}
                      alt="Close Icon"
                      style={{ width: "1.875rem", height: "1.875rem" }}
                    />
                  </button>
                  <img
                    src={astronaut}
                    alt="Astronaut"
                    style={{ width: "100px", height: "100px" }}
                  />
                  <span
                    style={{
                      // marginTop: "0.5625rem",
                      fontFamily: "MontserratBold",
                      fontWeight: "800",
                      fontSize: "30px",
                      color: "white",
                      lineHeight: "24px",
                      fontStyle: "italic",
                      textAlign: "center",
                      paddingTop: "25px",
                    }}
                    className="successModal__head"
                  >
                    Redeem Success
                  </span>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span
                      style={{
                        // marginTop: "0.5625rem",
                        fontFamily: "Montserrat",
                        fontWeight: "600",
                        fontSize: "16px",
                        color: "#F1F1F1",
                        textAlign: "center",
                        lineHeight: "24px",
                        paddingTop: "25px",
                        paddingBottom: "25px",
                      }}
                      className="redeemModal__text"
                    >
                      You have successfully redeemed 1,000
                      {/* {selectedCard?.value?.toLocaleString()}  */}
                      Points to 1 Raffle Tickets.
                    </span>
                  </div>

                  <div className="redeemModal__buttons">
                    <button
                      onClick={() => setSuccess(false)}
                      className="redeem__bottom-btn3"
                      style={{ width: "100%" }}
                    >
                      Awesome
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
      <NavBar />
    </Layout>
  );
};

export default Raffel;
