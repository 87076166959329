import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Layout from "../components/Layout/Layout";
import NavBar from "../components/NavBar/NavBar";

import { getMissionStatus } from "../api/getMissionStatus";
import { updateMissionStatus } from "../api/updateMissionStatus";
import { getMissions } from "../api/getMissions";
import "./Earn.css";
// import rocketIcon from "../assets/rocket.svg";
import moveIcon from "../assets/moveIcon.png";
import tickIcon from "../assets/tickIcon.png";
import notificationIcon from "../assets/notificationIcon.png";
import expandIcon from "../assets/expandIcon.png";
import collapseIcon from "../assets/collapseIcon.png";

import { formatNumber } from "../utils/formatNumber";
import Header from "../components/Header/Header";

const Earn = () => {
  const token = sessionStorage.getItem("token");
  const navigate = useNavigate();
  const [missions, setMissions] = useState([]);
  const [missionStatus, setMissionStatus] = useState({ missions: [] });
  const [notification, setNotification] = useState("");
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isNewTaskCollapsed, setIsNewTaskCollapsed] = useState(false);
  const [isEssentialTasksCollapsed, setIsEssentialTasksCollapsed] = useState();
  const [isBonusTasksCollapsed, setIsBonusTasksCollapsed] = useState(false);
  const [isEnglish, setIsEnglish] = useState(false);
  const [isAccessing, setIsAccessing] = useState(false);
  const [siteURL, setSiteURL] = useState(
    "https://discord.com/invite/bluemoon/"
  );

  useEffect(() => {
    const savedLanguage = sessionStorage.getItem("isEnglish");
    if (savedLanguage === "true") {
      setIsEnglish(true);
    } else {
      setIsEnglish(false);
    }

    const initiateMissionStatus = async () => {
      try {
        const missionData = await getMissions(token);
        console.log(missionData, "this is the missaion data");
        const initialMissionStatus = await getMissionStatus(token);
        setMissions(missionData);
        setMissionStatus(initialMissionStatus || { missions: [] });
      } catch (error) {
        console.error("Error fetching mission data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    initiateMissionStatus();
  }, [token]);

  const toggleNewTasksCollapse = () => {
    setIsNewTaskCollapsed(!isNewTaskCollapsed);
  };

  const toggleEssentialTasksCollapse = (index) => {
    setIsEssentialTasksCollapsed(index);
  };

  const toggleBonusTasksCollapse = () => {
    setIsBonusTasksCollapsed(!isBonusTasksCollapsed);
  };

  const handleClickMission = async (missionId) => {
    try {
      const mission = missions
        ?.map((m) => {
          const filteredTasks = m.tasks.filter(
            (task) => task._id === missionId
          );
          return {
            ...m,
            tasks: filteredTasks,
          };
        })
        .find((m) => m.tasks.length > 0);
      console.log(mission, missionId);
      console.log("click on mission", mission);
      const apiResult = await updateMissionStatus(token, missionId);

      if (mission.inviteCount > 0) {
        if (apiResult === false) {
          setNotification(`Task not completed`);
          // setNotificationVisibile(true);

          setTimeout(() => {
            // setNotificationVisibile(false);
            setTimeout(() => setNotification(""), 3000);
          }, 2000);
        } else {
          setNotification(`Task completed`);
          // setNotificationVisibile(true);
          // finished(mission._id);

          setTimeout(() => {
            // setNotificationVisibile(false);
            setTimeout(() => setNotification(""), 3000);
          }, 2000);
        }
      } else {
        let missionStatue = missionStatus;
        let arr = missionStatue.missions;
        // finished(mission._id);
        missionStatue.missions = arr.push(mission._id);
        setMissionStatus(missionStatue);
        // window.location.href = mission.link;
        if (mission?.tasks[0]?.link !== "") {
          console.log("this is mission url", mission?.tasks[0]?.link, mission);
          openInSafari(mission?.tasks[0]?.link);
          navigate("/?from=browser");
        }
        // return false
      }
    } catch (error) {
      console.log(error);
    }
  };
  const openInSafari = (url) => {
    window.location.href = url;
  };
  const goBackToApp = () => {
    setIsAccessing(false);
  };

  return (
    // isAccessing ? (
    //     <div style={{height:'100%', width:'100%', display:"flex", flexDirection:"column"}}>
    //         <button style={{ width: '100%', padding:"10px 0px", background: 'linear-gradient(45deg, #5DA8FF, #0064D7)', cursor: 'pointer', border: 'none', fontFamily: 'Poppins', color: 'white', fontSize: '1rem', fontWeight: '600' }} onClick={goBackToApp}>Back To App</button>
    //         <iframe style={{height:'100%', width:'100%', border:"none"}} referrerpolicy="no-referrer" loading="lazy" src="https://discord.com" title="description" allowFullScreen></iframe>
    //     </div>
    // ) : (
    <Layout>
      {isLoading ? (
        <div className="loading-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <>
          {/* <Header /> */}
          <div style={{ marginTop: "67px" }}>
            {/* <img
              src="/Images/back-arrow.svg"
              alt="back"
              onClick={() => {
                navigate(-1);
              }}
            /> */}
          </div>
          <div
            style={{
              backgroundImage: "url('/Images/Profile/boys.png')",
              backgroundRepeat: "no-repeat",
              backgroundSize: "350px 320px",
              backgroundPosition: "top center",
            }}
          >
            <div
              style={{
                background:
                  "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 46.52%)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  // position: "relative",
                  paddingTop: "300px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    //   paddingInline: "1rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "0rem",
                    textAlign: "center",
                    position: "relative",
                  }}
                >
                  <span
                    className="earnmoreponts"
                    style={{
                      color: "transparent",
                      fontFamily: "MontserratBold",

                      fontWeight: "800",
                      background:
                        "linear-gradient(91.61deg, #5D01A2 1.37%, #FFF204 169.85%)",
                      filter: "blur(30px)",
                      position: "relative",
                      zIndex: "1",
                      backgroundClip: "text",
                      fontStyle: "italic",
                      paddingLeft: "25px",
                      paddingRight: "25px",
                    }}
                  >
                    {isEnglish ? "Earn more Points" : "Guadagna più Punti"}
                  </span>
                  <span
                    className="earnmoreponts"
                    style={{
                      color: "#fff",
                      fontFamily: "MontserratBold",

                      fontWeight: "800",
                      position: "absolute",
                      fontStyle: "italic",
                      zIndex: "2",
                      paddingLeft: "25px",
                      paddingRight: "25px",
                      //   top: "0",
                    }}
                  >
                    {isEnglish ? "Earn more Points" : "Guadagna più Punti"}
                  </span>
                </div>

                <div
                  style={{
                    width: "100%",

                    display: "flex",
                    flexDirection: "column",
                    gap: "1.25rem",
                    marginTop: "25px",
                    marginBottom: "30px",
                  }}
                  className="div_main"
                >
                  {missions?.map((item, index) => {
                    if (item?.groupName === "New Task") console.log(missions);
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          backgroundColor: "#090909",
                          backdropFilter: "blur(0.5rem)",
                          WebkitBackdropFilter: "blur(0.5rem)",
                          borderRadius: "20px",
                          border: "2px solid #121212",
                        }}
                        className="div_main2"
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          // onClick={toggleNewTasksCollapse}
                        >
                          <span
                            style={{
                              fontFamily: "Montserrat",
                              fontWeight: "600",
                              // marginBottom: "10px",
                              color: "transparent",
                              background:
                                "linear-gradient(90.24deg, #DCE64F 0.06%, #F8FF96 43.96%)",
                              backgroundClip: "text",
                            }}
                            className="tasks-earn"
                          >
                            {item?.groupName}
                          </span>
                          <div
                            style={{
                              background: "transparent",
                              border: "none",
                              color: "white",
                            }}
                          >
                            {/* {isNewTaskCollapsed ? (
                          <img
                            src={expandIcon}
                            alt="Expand Icon"
                            style={{
                              width: "1.125rem",
                              height: "1.125rem",
                              transform: "rotate(180deg)",
                            }}
                          ></img>
                        ) : (
                          <img
                            src={collapseIcon}
                            alt="Collapse Icon"
                            style={{
                              width: "1.125rem",
                              height: "1.125rem",
                              transform: "rotate(90deg)",
                            }}
                          ></img>
                        )} */}
                          </div>
                        </div>
                        {/* {!isNewTaskCollapsed && ( */}
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            flexDirection: "column",
                            gap: "17px",
                            paddingTop: "8px",
                          }}
                        >
                          {item?.tasks?.map((mission) => (
                            <button
                              key={mission._id}
                              style={{
                                border: "none",
                                borderRadius: "15px",
                                padding: "12px 15px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                backgroundColor: "#0E0E0E",
                                cursor: missionStatus.missions.includes(
                                  mission._id
                                )
                                  ? "auto"
                                  : "pointer",
                                width: "100%",
                                opacity: missionStatus.missions.includes(
                                  mission._id
                                )
                                  ? "0.5"
                                  : "1",
                              }}
                              onClick={
                                missionStatus.missions.includes(mission._id)
                                  ? null
                                  : () => handleClickMission(mission._id)
                              }
                            >
                              <img
                                src={mission.photoUrl}
                                alt={mission.title}
                                style={{
                                  width: "2rem",
                                  height: "2rem",
                                  background: "rgba(140,140,140,0.1)",
                                  borderRadius: "50%",
                                  padding: "0.2rem",
                                }}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "start",
                                  marginLeft: "1rem",
                                  width: "100%",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "Montserrat",
                                    fontWeight: "500",

                                    color: "#FFFFFF",
                                    textAlign: "left",
                                  }}
                                  className="earn-missiontitle"
                                >
                                  {mission.title}
                                </span>
                                <div
                                  style={{
                                    fontFamily: "Montserrat",
                                    textAlign: "start",
                                    fontWeight: "400",

                                    color: "rgba(255,255,255,0.6)",
                                  }}
                                  className="earn-missiontitle"
                                >
                                  {isEnglish ? "Earn" : "Guadagna"}
                                  <span
                                    style={{
                                      fontFamily: "Montserrat",
                                      fontWeight: "500",
                                      marginInline: "0.2rem",
                                      color: "white",
                                    }}
                                  >
                                    +{formatNumber(mission.bonus)}
                                  </span>

                                  {isEnglish ? "orbitz points" : "punti orbite"}
                                </div>
                              </div>
                              {missionStatus.missions.includes(mission._id) ? (
                                <img
                                  src={tickIcon}
                                  alt="Tick Icon"
                                  style={{
                                    width: "1.25rem",
                                    height: "1.25rem",
                                  }}
                                />
                              ) : (
                                <img
                                  src={moveIcon}
                                  alt="Move Icon"
                                  style={{
                                    width: "1.25rem",
                                    height: "1.25rem",
                                    // transform: "rotate(90deg)",
                                  }}
                                />
                              )}
                            </button>
                          ))}
                        </div>
                        {/* )} */}
                      </div>
                    );
                  })}

                  {missions?.map((item, index) => {
                    if (item?.groupName !== "New Task")
                      return (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            backdropFilter: "blur(0.5rem)",
                            WebkitBackdropFilter: "blur(0.5rem)",
                            borderRadius: "20px",
                            border: "2px solid #121212",
                            backgroundColor: "#090909",
                          }}
                          className="div_main2"
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              toggleEssentialTasksCollapse(index);
                            }}
                          >
                            <span
                              style={{
                                color: "transparent",
                                background:
                                  "linear-gradient(90.24deg, #DCE64F 0.06%, #F8FF96 43.96%)",
                                backgroundClip: "text",
                                fontFamily: "Montserrat",
                                fontWeight: "600",
                              }}
                              className="tasks-earn"
                            >
                              {item?.groupName}
                            </span>
                            <div
                              style={{
                                background: "transparent",
                                border: "none",
                                color: "white",
                              }}
                            >
                              {isEssentialTasksCollapsed == index ? (
                                <img
                                  src={expandIcon}
                                  alt="Expand Icon"
                                  style={{
                                    width: "1.125rem",
                                    height: "1.125rem",
                                    transform: "rotate(180deg)",
                                  }}
                                ></img>
                              ) : (
                                <img
                                  src={collapseIcon}
                                  alt="Collapse Icon"
                                  style={{
                                    width: "1.125rem",
                                    height: "1.125rem",
                                    transform: "rotate(90deg)",
                                  }}
                                ></img>
                              )}
                            </div>
                          </div>
                          {isEssentialTasksCollapsed == index && (
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                marginTop: "10px",
                                justifyContent: "space-between",
                                alignItems: "center",
                                flexDirection: "column",
                                gap: "17px",
                              }}
                            >
                              {item?.tasks?.map((mission) => (
                                <button
                                  key={mission._id}
                                  style={{
                                    border: "none",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    borderRadius: "15px",
                                    padding: "12px 15px",
                                    height: "56px",
                                    backgroundColor: "#0E0E0E",
                                    cursor: missionStatus.missions.includes(
                                      mission._id
                                    )
                                      ? "auto"
                                      : "pointer",

                                    width: "100%",
                                    opacity: missionStatus.missions.includes(
                                      mission._id
                                    )
                                      ? "0.5"
                                      : "1",
                                  }}
                                  onClick={
                                    missionStatus.missions.includes(mission._id)
                                      ? null
                                      : () => handleClickMission(mission._id)
                                  }
                                >
                                  <img
                                    src={mission.photoUrl}
                                    alt={mission.title}
                                    style={{
                                      width: "2rem",
                                      height: "2rem",
                                      background: "rgba(140,140,140,0.1)",
                                      borderRadius: "50%",
                                      padding: "0.2rem",
                                    }}
                                  />
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "start",
                                      marginLeft: "1rem",
                                      width: "100%",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontFamily: "Montserrat",
                                        fontWeight: "500",

                                        color: "#FFFFFF",
                                      }}
                                      className="earn-missiontitle"
                                    >
                                      {mission.title}
                                    </span>
                                    <div
                                      style={{
                                        textAlign: "start",
                                        fontFamily: "Montserrat",
                                        fontWeight: "400",

                                        color: "rgba(255,255,255,0.6)",
                                      }}
                                      className="earn-missiontitle"
                                    >
                                      {isEnglish ? "Earn" : "Guadagna"}
                                      <span
                                        style={{
                                          fontFamily: "Montserrat",
                                          fontWeight: "500",
                                          marginInline: "0.2rem",
                                          color: "white",
                                        }}
                                      >
                                        +{formatNumber(mission.bonus)}
                                      </span>
                                      {isEnglish
                                        ? "orbitz points"
                                        : "punti orbite"}
                                    </div>
                                  </div>
                                  {missionStatus.missions.includes(
                                    mission._id
                                  ) ? (
                                    <img
                                      src={tickIcon}
                                      alt="Tick Icon"
                                      style={{
                                        width: "1.25rem",
                                        height: "1.25rem",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={moveIcon}
                                      alt="Move Icon"
                                      style={{
                                        width: "1.25rem",
                                        height: "1.25rem",
                                      }}
                                    />
                                  )}
                                </button>
                              ))}
                            </div>
                          )}
                        </div>
                      );
                  })}

                  <div style={{ textAlign: "center", marginTop: "2rem" }}>
                    {notificationVisible && (
                      <div
                        style={{
                          color: "white",
                          fontFamily: "Montserrat",
                          fontWeight: "600",
                          fontSize: "1rem",
                        }}
                      >
                        {notification}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <NavBar />
    </Layout>
  );
};

export default Earn;
