import React, { useEffect, useState } from "react";
import { getProfile } from "../../../api/getProfile";
import { useNavigate } from "react-router-dom";
import NavBar from "../../../components/NavBar/NavBar";
import Header from "../../../components/Header/Header";
import Layout from "../../../components/Layout/Layout";

export const RedeemFirst = () => {
  const [isLoading, setIsloading] = useState(true);
  const [totalPoints, setTotalPoints] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    setIsloading(true);
    const token = sessionStorage.getItem("token");

    async function getData() {
      if (token) {
        const data = await getProfile(token);
        setIsloading(false);
        setTotalPoints(data?.user?.score);
      }
    }
    getData();
  }, []);

  const [isEnglish, setIsEnglish] = useState(false);

  useEffect(() => {
    const savedLanguage = sessionStorage.getItem("isEnglish");
    if (savedLanguage === "true") {
      setIsEnglish(true);
    } else {
      setIsEnglish(false);
    }
  }, []);

  return (
    <Layout>
      {isLoading ? (
        <div className="loading-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <>
          {/* <Header/> */}
          <div style={{ marginTop: "52px" }}>
            {/* <img
              src="/Images/back-arrow.svg"
              alt="back"
              onClick={() => {
                navigate(-1);
              }}
            /> */}
          </div>
          <div className="topredeem">
            <div className="redeem__topbox">
              <p>{isEnglish ? "Available Points" : "Punti disponibili"}</p>
              <div className="redeem__topbox-points">
                <img
                  src="/Images/image.png"
                  alt="/"
                  style={{ width: "25px", height: "25px" }}
                />
                <p>{totalPoints ? totalPoints.toLocaleString() : 0}</p>
              </div>
              <div className="redeem__topbox-btn">
                <button
                  onClick={() => {
                    navigate("/earn");
                  }}
                  className="redeem__topbox-btn1"
                >
                  {isEnglish ? "Get Points" : "Ottieni Punti"}
                </button>
                <button
                  onClick={() => {
                    navigate("/history");
                  }}
                  className="redeem__topbox-btn2"
                >
                  {isEnglish ? "Points History" : "Cronologia Punti"}
                </button>
              </div>
            </div>
            <p className="redeemFirst_text">
              {isEnglish ? "Redeem Points" : "Riscatta Punti"}
            </p>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <div className="redeemFirst__btn">
                <p>
                  {isEnglish
                    ? "Redeem to Shop Points"
                    : "Riscatta per Punti Shop"}
                </p>
                <img
                  onClick={() => {
                    navigate("/Redeem");
                  }}
                  src="/Images/arrow.svg"
                  alt="/"
                />
              </div>
              <div
                onClick={() => {
                  navigate("/RedeemUsdt");
                }}
                className="redeemFirst__btn"
              >
                <p>{isEnglish ? "Redeem to USDT" : "Riscatta in USDT"}</p>
                <img src="/Images/arrow.svg" alt="/" />
              </div>
              <div
                onClick={() => {
                  navigate("/raffel");
                }}
                className="redeemFirst__btn"
              >
                <p>
                  {isEnglish
                    ? "Redeem to Raffle Tickets"
                    : "Riscatta in Biglietti della Lotteria"}
                </p>
                <img src="/Images/arrow.svg" alt="/" />
              </div>
            </div>
          </div>
        </>
      )}
      <NavBar />
    </Layout>
  );
};
