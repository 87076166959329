import React, { useEffect, useState } from "react";
import "./History.css";
import { getProfile } from "../api/getProfile";
import { getRedeemHistory } from "../api/getReedeemHostory";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header/Header";
import Layout from "../components/Layout/Layout";
import { getUserHistory } from "../api/getUserHistory";

const History = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [redeemHistory, setRedeemHistory] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    const token = sessionStorage.getItem("token");
    async function getData() {
      if (token) {
        const data = await getProfile(token);
        const userRedeemHistory = await getUserHistory(
          data?.user?._id,
          token
        );
        console.log(userRedeemHistory, "this is redeem data");

        // Set the redeem history state
        setRedeemHistory(userRedeemHistory);
        setIsLoading(false);
      }
    }
    getData();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate(); // 5
    const month = date.toLocaleString("default", { month: "short" }); // 'Sep'
    const year = date.getFullYear(); // 2024
    return `${day} ${month} ${year}`; // "5 Sep 2024"
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const period = hours >= 12 ? "PM" : "AM";

    // Convert to 12-hour format
    hours = hours % 12 || 12; // Convert 0 to 12 for midnight

    return `${hours}:${minutes < 10 ? "0" : ""}${minutes} ${period}`; // "5:00 PM"
  };

  return (
    <Layout>
      {isLoading ? (
        <div className="loading-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <>
          {/* <Header/> */}
          <div className="header__history">
            <img
              src="/Images/back-arrow.svg"
              alt="back"
              onClick={() => {
                navigate(-1);
              }}
            />
            <p className="header__history-txt">Points History</p>
          </div>
          <div className="top-container-history">
            {Object.keys(redeemHistory).map((date) => (
              <div className="main-date-container" key={date}>
                <p className="date-para">{formatDate(date)}</p>
                <div className="data-content-main">
                  {redeemHistory[date].map((transaction) => (
                    <div className="date-content" key={transaction._id}>
                      <div className="date-content-d1">
                        <h1>{transaction.points} Points</h1>
                        <p>{transaction.message}</p>
                      </div>
                      <p className="date-content-p">
                        {formatTime(transaction?.createdAt)}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </Layout>
  );
};

export default History;
