import React, { useRef, useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import NavBar from "../components/NavBar/NavBar";
import astronaut from "../assets/astronaut.png";
import closeIcon from "../assets/closeIcon.png";
import { Box, Typography } from "@mui/material";
import be from "../assets/be.svg";
import "./Redeem.css";
import { Card, CardContent } from "@mui/material";
import { getActiveElement } from "@testing-library/user-event/dist/utils";
import { getProfile } from "../api/getProfile";
import { redeemUser } from "../api/Redeem";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header/Header";
const data = [
  { title: "Free Subscription", amount: "1,000" },
  { title: "WOW Platform", amount: "10,000" },
  { title: "WOW Platform", amount: "50,000" },
  { title: "WOW Platform", amount: "100,000" },
  { title: "WOW Platform", amount: "250,000" },
  { title: "WOW Platform", amount: "500,000" },
  { title: "WOW Platform", amount: "1,000,000" },
  { title: "WOW Platform", amount: "2,000,000" },
  { title: "WOW Platform", amount: "5,000,000" },
  { title: "WOW Platform", amount: "10,000,000" },
];
const cardData = [
  { value: 100000, image: "/missionImages/card1.png" }, // Replace with your actual image URLs
  { value: 200000, image: "/missionImages/card2.png" },
  { value: 300000, image: "/missionImages/card3.png" },
  { value: 400000, image: "/missionImages/card4.png" },
];

const Redeem = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [totalPoints, setTotalPoints] = useState();
  const [isLoading, setIsloading] = useState(true);
  const [userPoints, setUserPoints] = useState();
  const [updateLoader, setUpdateLoader] = useState(false);
  const [beId, setbeId] = useState();
  const [selectedCard, setSelectedCard] = useState();
  const [userId, setUserId] = useState();
  const [update, setUpdate] = useState();
  const [confirmRedeem, setConfirmRedeem] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    if (dropdownVisible) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [dropdownVisible, setDropdownVisible]);
  const toggleDropdown = async () => {
    if (!beId || beId === "" || beId == 0) {
      setDropdownVisible(!dropdownVisible);
      return;
    }
    setConfirmRedeem(true);
  };
  const handleSuccess = () => {
    setSuccess(true);
  };

  const handleSave = async () => {
    const url = `https://api-stg.fastshoppingstore.com/points-exchange/calculate`;

    // Prepare the body data to be sent with the POST request
    const bodyData = {
      currencyTo: "shop-points",
      currencyFrom: "be-points",
      customerId: parseInt(beId),
      amount: selectedCard?.value,
    };

    try {
      // const response = await fetch(url, {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json", // Specify the content type
      //     "X-API-Key":
      //       "cWr2leRXmMOqZqojXsLKS3JoOP8P6xiCvfcZ5TZQz0kqDUZljMhm8U2HNoPbZh2B",
      //   },
      //   body: JSON.stringify(bodyData), // Convert the body data to a JSON string
      // });

      // if (!response.ok) {
      //   throw new Error(`HTTP error! status: ${response.status}`);
      // }

      // const data = await response.json();
      setIsloading(true);
      handleSuccess();
      const dataOfRedeem = await redeemUser(
        userId,
        selectedCard?.value,
        "shop",
        "",
        "",
        selectedCard?.value * userPoints
      );
      console.log("userRedeemExchange", dataOfRedeem);
      setConfirmRedeem(false);
      handleSuccess();
      setUpdateLoader(false);
      setUpdate(dataOfRedeem);
      return data?.data?.rate;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const closeDropdown = () => {
    setDropdownVisible(false);
  };
  const [act, setact] = useState("");
  const handleborder = (value) => {
    setact(value);
  };

  useEffect(() => {
    setIsloading(true);
    const token = sessionStorage.getItem("token");
    async function getData() {
      if (token) {
        const data = await getProfile(token);

        setbeId(data?.user?.beUserId);
        setTotalPoints(data?.user?.score);
        setUserId(data?.user?._id);
        setIsloading(false);
      }
    }
    getData();
  }, [update]);

  const validateCustomer = async (customerId) => {
    const url = `https://api.fastshoppingstore.com/points-exchange/rate?currencyFrom=be-points&currencyTo=shop-points`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "X-API-Key":
            "MJelS1yGLXabynwZg2u9XVmCGQcuFn42GM9KrvZ4hYeaAPnHpEWHE8fXGl7lg4QS",
        },
      });

      console.log("this is returning redeem point", response);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      setUserPoints(data?.data?.rate);
      return data?.data?.rate;
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    validateCustomer();
  }, []);
  useEffect(() => {
    if (dropdownVisible) {
      // Disable background scrolling by setting body overflow to hidden
      document.body.style.overflow = "hidden";
    } else {
      // Re-enable scrolling when the dropdown is not visible
      document.body.style.overflow = "auto";
    }

    // Cleanup function to restore scrolling when the component unmounts or dropdownVisible changes
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [dropdownVisible]);

  useEffect(() => {
    if (success) {
      // Disable background scrolling by setting body overflow to hidden
      document.body.style.overflow = "hidden";
    } else {
      // Re-enable scrolling when the dropdown is not visible
      document.body.style.overflow = "auto";
    }
    // Cleanup function to restore scrolling when the component unmounts or dropdownVisible changes
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [success]);
  useEffect(() => {
    if (confirmRedeem) {
      // Disable background scrolling by setting body overflow to hidden
      document.body.style.overflow = "hidden";
    } else {
      // Re-enable scrolling when the dropdown is not visible
      document.body.style.overflow = "auto";
    }
    // Cleanup function to restore scrolling when the component unmounts or dropdownVisible changes
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [confirmRedeem]);

  const [isEnglish, setIsEnglish] = useState(false);

  useEffect(() => {
    const savedLanguage = sessionStorage.getItem("isEnglish");
    if (savedLanguage === "true") {
      setIsEnglish(true);
    } else {
      setIsEnglish(false);
    }
  }, []);

  return (
    <Layout>
      {isLoading ? (
        <div className="loading-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <>
          {/* <Header /> */}
          <div className="back-icon">
            <img
              src="/Images/back-arrow.svg"
              alt="back"
              qqqq
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
          <div className="topredeem">
            <div className="redeem__topbox">
              <p>{isEnglish ? "Available Points" : "Punti disponibili"}</p>
              <div className="redeem__topbox-points">
                <img
                  src="/Images/image.png"
                  alt="/"
                  style={{ width: "25px", height: "25px" }}
                />
                <p>{totalPoints ? totalPoints.toLocaleString() : 0}</p>
              </div>
              <div className="redeem__topbox-btn">
                <button
                  onClick={() => {
                    navigate("/earn");
                  }}
                  className="redeem__topbox-btn1"
                >
                  {isEnglish ? "Get Points" : "Ottieni Punti"}
                </button>
                <button
                  onClick={() => {
                    navigate("/history");
                  }}
                  className="redeem__topbox-btn2"
                >
                  {isEnglish ? "Points History" : "Cronologia Punti"}
                </button>
              </div>
            </div>
            <div className="cardscontainer">
              <p className="cardscontainer_text">
                {isEnglish ? "Live Exchange Rate " : "Tasso di Cambio Attuale "}
                {userPoints / 1}:1
              </p>
              <Box
                display="grid"
                gridTemplateColumns="repeat(2, 1fr)"
                justifyContent="center"
                gap="14px"
                sx={{ width: "100%" }}
              >
                {cardData.map((card, index) => (
                  <Card
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      background: "#090909",
                      width: "100%",
                      height: "235px",
                      // padding: "0px 0px 20px 0px",
                      borderRadius: "10px",
                      border: "2px solid #121212",
                      overflow: "hidden",
                      border:
                        act === "A" && index === 0
                          ? "2px solid transparent"
                          : act === "B" && index === 1
                          ? "2px solid transparent"
                          : act === "C" && index === 2
                          ? "2px solid transparent"
                          : act === "D" && index === 3
                          ? "2px solid transparent"
                          : "2px solid #121212",

                      background:
                        act === "A" && index === 0
                          ? `
                  linear-gradient(#090909, #090909) padding-box, 
                  linear-gradient(92.23deg, #FF00D9 -19.69%, #6C4EFE 100%) border-box
                `
                          : act === "B" && index === 1
                          ? `
                  linear-gradient(#090909, #090909) padding-box, 
                  linear-gradient(92.23deg, #FF00D9 -19.69%, #6C4EFE 100%)border-box
                `
                          : act === "C" && index === 2
                          ? `
                  linear-gradient(#090909, #090909) padding-box, 
                  linear-gradient(92.23deg, #FF00D9 -19.69%, #6C4EFE 100%) border-box
                `
                          : act === "D" && index === 3
                          ? `
                  linear-gradient(#090909, #090909) padding-box, 
                  linear-gradient(92.23deg, #FF00D9 -19.69%, #6C4EFE 100%) border-box
                `
                          : "#090909",
                    }}
                    onClick={() => {
                      if (index === 0) handleborder("A");
                      if (index === 1) handleborder("B");
                      if (index === 2) handleborder("C");
                      if (index === 3) handleborder("D");

                      setSelectedCard(card);
                    }}
                  >
                    <div className="card-image-container">
                      <div className="cardtop">
                        {/* <div style={{ display: "flex" }}>
                          <img src="/missionImages/card-be.svg" alt="be" />
                        </div> */}
                        <div className="cardtop-inner">
                          <h1 className="cardtop-inner-text1">
                            {card.value * userPoints}
                          </h1>
                          <p className="cardtop-inner-text2">
                            {isEnglish ? "SHOP POINTS" : "PUNTI SHOP"}
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <img src={card.image} alt="1" style={{ width: "100%" }} /> */}

                    <CardContent
                      sx={{
                        flex: "1",

                        padding: "none",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0px 12px 0px 12px",
                        color: "white",
                        marginTop: "10px",
                      }}
                    >
                      <Typography className="cardtext1">
                        {isEnglish ? "Points:" : "Punti:"}
                      </Typography>
                      <Typography className="cardtext1">
                        {card.value.toLocaleString()}
                      </Typography>
                    </CardContent>
                  </Card>
                ))}
              </Box>
            </div>
            <div className="bottomButtondiv">
              <button
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                // disabled={
                //   selectedCard
                //     ? false
                //     : parseInt(totalPoints) > selectedCard?.value
                //     ? false
                //     : true
                // }
                disabled={totalPoints >= selectedCard?.value ? false : true}
                className="redeem__bottom-btn"
                onClick={toggleDropdown}
              >
                <p> {isEnglish ? "Redeem Now" : "Riscatta Ora"}</p>
                {updateLoader && <CircularProgress />}
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {dropdownVisible && (
              <>
                <div
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.1)", // Black background with 10% opacity
                    backdropFilter: "blur(5px)", // Backdrop blur effect
                    WebkitBackdropFilter: "blur(5px)", // Backdrop blur effect for Safari
                    zIndex: 998, // Behind the dropdown
                  }}
                ></div>
                <div
                  ref={dropdownRef}
                  style={{
                    width: "90%",
                    borderRadius: "1.25rem 1.25rem 1.25rem 1.25rem",
                    background: "#8C8C8C1A",
                    backdropFilter: "blur(3rem)",
                    WebkitBackdropFilter: "blur(3rem)",
                    position: "fixed",
                    bottom: "25%",
                    display: "flex",
                    flexDirection: "column",
                    padding: "32px 35px",
                    alignItems: "center",
                    // gap: "25px",
                    zIndex: "999",
                  }}
                  className="redeemModal"
                >
                  <button
                    style={{
                      position: "absolute",
                      top: "1rem",
                      right: "1rem",
                      float: "right",
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                    onClick={closeDropdown}
                  >
                    <img
                      src={closeIcon}
                      alt="Close Icon"
                      style={{ width: "1.875rem", height: "1.875rem" }}
                    />
                  </button>
                  <img
                    src={astronaut}
                    alt="Astronaut"
                    style={{ width: "100px", height: "100px" }}
                  />
                  <span
                    style={{
                      // marginTop: "0.5625rem",
                      fontFamily: "MontserratBold",
                      fontWeight: "800",
                      fontSize: "36px",
                      color: "white",
                      lineHeight: "24px",
                      fontStyle: "italic",
                    }}
                    className="redeemModal__head"
                  >
                    Redeem
                  </span>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span
                      style={{
                        // marginTop: "0.5625rem",
                        fontFamily: "Montserrat",
                        fontWeight: "600",
                        fontSize: "16px",
                        color: "#F1F1F1",
                        textAlign: "center",
                        lineHeight: "24px",
                        paddingTop: "25px",
                        paddingBottom: "25px",
                      }}
                      className="redeemModal__text"
                    >
                      You don't have a BE id attached, Please update your
                      profile or create a Orbitz account.
                    </span>
                    {/* <span
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "16px",
                  color: "#F1F1F1",
                }}
              >
                and Earn Moon Coins even faster
              </span> */}
                  </div>

                  <div className="redeemModal__buttons">
                    <button
                      onClick={() => {
                        navigate("/editProfile");
                      }}
                      className="redeemModal-btn1"
                    >
                      Update Profile
                    </button>
                    <button className="redeemModal-btn2">Signup to BE</button>
                  </div>
                </div>
              </>
            )}
            {confirmRedeem && (
              <>
                <div
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.1)", // Black background with 10% opacity
                    backdropFilter: "blur(5px)", // Backdrop blur effect
                    WebkitBackdropFilter: "blur(5px)", // Backdrop blur effect for Safari
                    zIndex: 998, // Behind the dropdown
                  }}
                ></div>
                <div
                  ref={dropdownRef}
                  style={{
                    width: "90%",
                    borderRadius: "1.25rem 1.25rem 1.25rem 1.25rem",
                    background: "#8C8C8C1A",
                    backdropFilter: "blur(3rem)",
                    WebkitBackdropFilter: "blur(3rem)",
                    position: "fixed",
                    bottom: "25%",
                    display: "flex",
                    flexDirection: "column",
                    padding: "32px 35px",
                    alignItems: "center",
                    // gap: "25px",
                    zIndex: "999",
                  }}
                  className="redeemModal"
                >
                  <button
                    style={{
                      position: "absolute",
                      top: "1rem",
                      right: "1rem",
                      float: "right",
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setConfirmRedeem(false);
                    }}
                  >
                    <img
                      src={closeIcon}
                      alt="Close Icon"
                      style={{ width: "1.875rem", height: "1.875rem" }}
                    />
                  </button>
                  <img
                    src={astronaut}
                    alt="Astronaut"
                    style={{ width: "100px", height: "100px" }}
                  />
                  <span
                    style={{
                      // marginTop: "0.5625rem",
                      fontFamily: "MontserratBold",
                      fontWeight: "800",
                      fontSize: "36px",
                      color: "white",
                      lineHeight: "24px",
                      fontStyle: "italic",
                    }}
                    className="redeemModal__head"
                  >
                    Redeem
                  </span>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span
                      style={{
                        // marginTop: "0.5625rem",
                        fontFamily: "Montserrat",
                        fontWeight: "600",
                        fontSize: "16px",
                        color: "#F1F1F1",
                        textAlign: "center",
                        lineHeight: "24px",
                        paddingTop: "25px",
                        paddingBottom: "25px",
                      }}
                      className="redeemModal__text"
                    >
                      Are you sure you want to redeem{" "}
                      {selectedCard?.value?.toLocaleString()} Points to Shop
                      Points?
                    </span>
                    {/* <span
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "16px",
                  color: "#F1F1F1",
                }}
              >
                and Earn Moon Coins even faster
              </span> */}
                  </div>
                  {/* <div style={{ marginTop: "0.5625rem" }}>
              <span
                style={{
                  marginRight: "0.25rem",
                  fontFamily: "Montserrat",
                  fontWeight: "800",
                  fontSize: "28px",
                  color: "white",
                }}
              >
                500,000
              </span>
              <span
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "400",
                  fontSize: "1.125rem",
                  color: "#F1F1F1",
                  opacity: "0.6",
                }}
              >
                / per clan
              </span>
            </div> */}
                  <div className="redeemModal__buttons">
                    <button
                      onClick={() => {
                        setConfirmRedeem(false);
                      }}
                      className="redeem__bottom-btn2"
                    >
                      Go Back
                    </button>
                    <button
                      onClick={() => {
                        handleSave();
                      }}
                      className="redeem__bottom-btn3"
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </>
            )}
            {success && (
              <>
                <div
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.1)", // Black background with 10% opacity
                    backdropFilter: "blur(5px)", // Backdrop blur effect
                    WebkitBackdropFilter: "blur(5px)", // Backdrop blur effect for Safari
                    zIndex: 998, // Behind the dropdown
                  }}
                ></div>
                <div
                  ref={dropdownRef}
                  style={{
                    width: "90%",
                    borderRadius: "1.25rem 1.25rem 1.25rem 1.25rem",
                    background: "#8C8C8C1A",
                    backdropFilter: "blur(3rem)",
                    WebkitBackdropFilter: "blur(3rem)",
                    position: "fixed",
                    bottom: "25%",
                    display: "flex",
                    flexDirection: "column",
                    padding: "32px 35px",
                    alignItems: "center",
                    // gap: "25px",
                    zIndex: "999",
                  }}
                  className="redeemModal"
                >
                  <button
                    style={{
                      position: "absolute",
                      top: "1rem",
                      right: "1rem",
                      float: "right",
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setSuccess(false);
                    }}
                  >
                    <img
                      src={closeIcon}
                      alt="Close Icon"
                      style={{ width: "1.875rem", height: "1.875rem" }}
                    />
                  </button>
                  <img
                    src={astronaut}
                    alt="Astronaut"
                    style={{ width: "100px", height: "100px" }}
                  />
                  <span
                    style={{
                      // marginTop: "0.5625rem",
                      fontFamily: "MontserratBold",
                      fontWeight: "800",
                      fontSize: "30px",
                      color: "white",
                      lineHeight: "24px",
                      fontStyle: "italic",
                      textAlign: "center",
                      paddingTop: "25px",
                    }}
                    className="successModal__head"
                  >
                    Redeem Success
                  </span>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span
                      style={{
                        // marginTop: "0.5625rem",
                        fontFamily: "Montserrat",
                        fontWeight: "600",
                        fontSize: "16px",
                        color: "#F1F1F1",
                        textAlign: "center",
                        lineHeight: "24px",
                        paddingTop: "25px",
                        paddingBottom: "25px",
                      }}
                      className="redeemModal__text"
                    >
                      You have successfully redeemed{" "}
                      {selectedCard?.value?.toLocaleString()} Points to{" "}
                      {selectedCard?.value * userPoints} Shop Points.
                    </span>
                  </div>

                  <div className="redeemModal__buttons">
                    <button
                      onClick={() => setSuccess(false)}
                      className="redeem__bottom-btn3"
                      style={{ width: "100%" }}
                    >
                      Awesome
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
      <NavBar />
    </Layout>
  );
};

export default Redeem;
