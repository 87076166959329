// src/App.js
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import JoinClan from "./pages/JoinClan";
import Clan from "./pages/Clan";
import Invite from "./pages/Invite";
import Flip from "./pages/Flip";
import Earn from "./pages/Earn";
import Boosts from "./pages/Boosts";
import Presale from "./pages/Presale";
import Levels from "./pages/Levels";
import "./App.css";
import Profile from "./pages/Profile/Profile";
import EditProfile from "./pages/Profile/EditProfile";
import Redeem from "./pages/Redeem";
import History from "./pages/History";
import { ToastContainer } from "react-toastify";
import Communities from "./pages/Communities/Communities";
import { RedeemFirst } from "./pages/Communities/RedeemFirst/RedeemFirst";
import RedeemUSDT from "./pages/RedeemUSDT";
import Raffel from "./pages/Raffel/Raffel";

const App = () => {
  useEffect(() => {
    if (
      window.Telegram &&
      window.Telegram.WebApp &&
      window.Telegram.WebApp.disableVerticalSwipes
    ) {
      window.Telegram.WebApp.disableVerticalSwipes();
      window.Telegram.WebApp.expand();
    } else {
      console.error("Telegram WebApp API is not available.");
    }
  });

  return (
    <>
      <ToastContainer
        position="center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        // theme="light"
      />
      <Router>
        <Routes>
          {/* <Route path="/" element={<HomePage />} /> */}
          <Route path="/joinclan" element={<JoinClan />} />
          <Route path="/joinClan/:id" element={<Clan />} />
          <Route path="/invite" element={<Invite />} />
          {/* <Route path="/flip" element={<Flip />} /> */}
          <Route path="/earn" element={<Earn />} />
          <Route path="/boosts" element={<Boosts />} />
          {/* <Route path='/presale' element={<Presale />} /> */}
          {/* <Route path='/levels' element={<Levels />} /> */}
          <Route path="/" element={<Profile />} />
          <Route path="/editProfile" element={<EditProfile />} />
          <Route path="/Redeem" element={<Redeem />} />
          <Route path="/RedeemUsdt" element={<RedeemUSDT />} />

          <Route path="/history" element={<History />} />
          <Route path="/community/:id" element={<Communities />} />
          <Route path="/redeemfirst" element={<RedeemFirst />} />
          <Route path="/raffel" element={<Raffel />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
